<script setup lang="ts">
const props = defineProps<{ count: number | boolean }>()

const displayCount = computed(() => {
  if (typeof props.count === 'number') return props.count > 9 ? '9+' : props.count.toString()
  return ''
})
</script>

<template>
  <div
    v-if="count || (typeof count === 'number' && count > 0)"
    class="text-white flex items-center justify-center rounded-1/2 leading-none text-xxs"
    :class="{
      'h-5 w-5 bg-danger': displayCount.length > 0,
      'h-4 w-4 bg-primary': displayCount.length < 1,
    }"
  >
    {{ displayCount }}
  </div>
</template>
