<script setup lang="ts">
import { computed } from 'vue'

import { type Icons, iconList } from '@/core/utils/icons'
import { type TransactionStateKey } from '@/merchant/api/transaction'
import { transactionStateIcon } from '@/core/utils/transaction'

const props = withDefaults(
  defineProps<{
    icon?: Icons | string
    size?: 'sm' | 'base' | 'md' | 'lg' | string
    transactionState?: TransactionStateKey
  }>(),
  {
    size: 'base',
  },
)
const computedSize = computed<string | null>(() =>
  !isNaN(Number(props.size)) ? `w-${props.size} h-${props.size} min-w-${props.size}` : null,
)
const displayIcon = computed(() => {
  if (props.icon) return props.icon as Icons
  if (props.transactionState) return transactionStateIcon[props.transactionState]
  return 'base'
})
</script>

<template>
  <component
    :is="iconList[displayIcon]"
    :class="[
      {
        'h-[0.875rem] w-[0.875rem] min-w-[0.875rem]': size === 'sm',
        'h-4 w-4 min-w-4': size === 'base',
        'h-5 w-5 min-w-5': size === 'md',
        'h-10 w-10 min-w-10': size === 'lg',
      },
      computedSize,
    ]"
  />
</template>
