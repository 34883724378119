import { z } from 'zod'

import instance, { mapData } from '@/core/api/client'

import type { Registration } from '@/merchant/api/registration'

// import type { Company, PointOfSale } from '@/merchant/api/company'
// import type { RegistrationLocale } from '@/merchant/api/registration'
//
// import axios, { type AxiosResponse } from 'axios'
//
// import type { Country } from '../utils/countries'
// import type { CountryCode } from 'libphonenumber-js'

export type AuthToken = {
  access_token: string
  created_at: number
  expires_in: number
  refresh_token: string
  token_type: 'Bearer'
}

type LoginResponse = {
  token: AuthToken
}

const loginParser = z.object({
  email: z.string().email(),
  password: z.string(),
})

export type LoginOptions = z.infer<typeof loginParser>

export const login = async (options: LoginOptions) => {
  const loginPayload = loginParser.parse(options)

  const res = await instance.post<LoginResponse>('/session', loginPayload)

  return res.data
}

const registrationParser = z.object({
  partner_token: z.string().optional(),
  sponsorship_token: z.string().optional(),
  registration: z.object({
    email: z.string().email(),
    first_name: z.string(),
    last_name: z.string(),
    password: z.string(),
    mobile_phone_number: z.string(),
    mobile_phone_number_country_code: z.string(),
    analytics_trackers: z.record(z.string()),
  }),
})

export type CreateRegistrationPayload = z.infer<typeof registrationParser>

export interface CreateRegistrationResponseData {
  registration: Registration
  metadata?: {
    token?: AuthToken
  }
}

export const register = async (_options: CreateRegistrationPayload) => {
  const options = registrationParser.parse(_options)

  const res = await instance<CreateRegistrationResponseData>('/registration', {
    method: options.partner_token ? 'PATCH' : 'POST',
    data: options,
    params: {
      email: options.registration.email,
    },
  })

  return mapData(res.data, true)
}

export interface LogoutResponse {
  message: string
}

export const logout = async () => {
  return await instance.delete<LogoutResponse>('/session')
}

export const userSchema = z.object({
  uuid: z.string(),
  locale: z.string(),
  email: z.string(),
  unconfirmed_email: z.null(),
  mobile_phone_number: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  street1: z.null(),
  street2: z.null(),
  zip_code: z.null(),
  city: z.null(),
  place_of_birth: z.string(),
  city_of_birth_code: z.string(),
  notification_authentication_success: z.boolean(),
  notification_transaction_validated: z.boolean(),
  notification_transaction_validated_minimal_amount: z.null(),
  notification_message_sent: z.boolean(),
  confirmed_at: z.string(),
  mobile_phone_number_confirmed_at: z.null(),
  not_available_notify_set_at: z.null(),
  not_available_country_code: z.null(),
  closure_confirmed_at: z.null(),
  closure_scheduled_at: z.null(),
  closure_notice_until: z.null(),
  country: z.null(),
  country_of_birth: z.object({ code: z.string(), name: z.string() }),
  mobile_phone_number_country: z.object({
    code: z.string(),
    name: z.string(),
  }),
  not_available_country: z.null(),
  two_factor_enabled: z.boolean(),
  avatar_url: z.null(),
  pending_sponsorship_request: z.boolean(),
  devices: z.array(
    z.object({
      uuid: z.string(),
      name: z.string(),
      os: z.string(),
      os_version: z.string(),
      app_version: z.string(),
      push_key: z.string(),
      suspended_at: z.null(),
    }),
  ),
  companies: z.array(
    z.object({
      id: z.number(),
      title: z.string(),
      siren: z.string(),
      street1: z.string(),
      street2: z.null(),
      zip_code: z.string(),
      city: z.string(),
      address: z.string(),
      contract_signed_at: z.null(),
      suspended_at: z.null(),
      description: z.string(),
      vat_number: z.string(),
      naf_code: z.string(),
      industrial_or_sales_or_service_activity: z.boolean(),
      sale_of_goods_or_service: z.boolean(),
      nif: z.null(),
      eai_certificate_to_sign: z.boolean(),
      capital: z.boolean(),
      personal: z.boolean(),
      nif_country_code: z.null(),
      legal_person: z.boolean(),
      country: z.object({ code: z.string(), name: z.string() }),
      qr_code_logo_url: z.null(),
      permissions: z.object({
        can_sign_contract: z.boolean(),
        can_create_merchant_identity_verification: z.boolean(),
        can_update_information: z.boolean(),
      }),
      identity_verifications: z.object({
        merchant: z.object({
          check_state: z.string(),
          created_at: z.string(),
          updated_at: z.string(),
          url: z.null(),
        }),
        legal_representative: z.object({
          check_state: z.string(),
          identity_confirmed_at: z.string(),
          email_sent_at: z.null(),
          email: z.null(),
        }),
      }),
    }),
  ),
  point_of_sales: z.array(
    z.object({
      company_id: z.number(),
      id: z.number(),
      siret: z.string(),
      title: z.string(),
      transfer_title: z.string(),
      website_url: z.null(),
      gcu_url: z.null(),
      privacy_policy_url: z.null(),
      terms_url: z.null(),
      support_url: z.null(),
      facebook_url: z.null(),
      twitter_url: z.null(),
      instagram_url: z.null(),
      street1: z.string(),
      street2: z.null(),
      zip_code: z.string(),
      city: z.string(),
      latitude: z.number(),
      longitude: z.number(),
      address: z.string(),
      online_visible: z.boolean(),
      offline_visible: z.boolean(),
      settings_seen_at: z.string(),
      suspended_at: z.null(),
      closure_confirmed_at: z.null(),
      closure_scheduled_at: z.null(),
      production_mode_enabled: z.boolean(),
      country: z.object({ code: z.string(), name: z.string() }),
      logo_url: z.null(),
      completed_sections: z.object({
        company: z.boolean(),
        point_of_sale: z.boolean(),
        bank: z.boolean(),
        signature: z.boolean(),
      }),
      skip_eai_certificate: z.boolean(),
      missing_documents: z.object({
        eai_certificate: z.boolean(),
        status: z.boolean(),
        substantiating: z.boolean(),
      }),
      missing_merchant_information: z.boolean(),
      permissions: z.object({
        can_create_transaction: z.boolean(),
        can_create_sponsorship_request: z.boolean(),
      }),
      closure_notice_until: z.null(),
      balance: z.object({ raw: z.string(), localized: z.string() }),
    }),
  ),
  disabled_payment_types: z.array(z.unknown()),
})

// @NOTE: I hope some day, we use this type...
// type User = z.infer<typeof userSchema>

type GetUserResponse = {
  registration: z.infer<typeof userSchema>
}

export const getUser = async () => {
  const res = await instance.get<GetUserResponse>('/registration')

  return res.data
}

export interface GetTwoFactorAuthResponseData {
  enabled: boolean
}

export interface GetTwoFactorCheckResponseData {
  message: string
}

export const checkTwoFactorAuth = async (opt: string) => {
  const res = await instance.post<GetTwoFactorCheckResponseData>(
    '/registration/two_factor_authentication_verification',
    { verification: { otp_attempt: opt } },
  )

  return res
}

export const getTwoFactorAuth = async () => {
  const res = await instance.get<GetTwoFactorAuthResponseData>('/registration/two_factor_authentication_activation')

  return res.data
}
