<script setup lang="ts">
import { iconList, type Icons } from '@/core/utils/icons'
import { transactionStateIcon } from '@/core/utils/transaction'
import type { TransactionStateKey } from '@/merchant/api/transaction'
import { computed } from 'vue'

const props = defineProps<{
  icon?: Icons
  transactionState?: TransactionStateKey
}>()

const displayIcon = computed(() => {
  if (props.icon) return props.icon
  if (props.transactionState) return transactionStateIcon[props.transactionState]
  return 'base'
})
</script>

<template>
  <component :is="iconList[displayIcon]" />
</template>
