import type { Router } from 'vue-router'

export type UserModule = ({ app, router }: { app: any; router: Router }) => void

export type PanelKey = 'range' | 'week' | 'month' | 'trimester' | 'year'
export type PresetKey = 'custom' | 'today' | 'yesterday' | 'last7days' | 'last30days' | 'last90days' | 'last365days'

export interface Range {
  from: Date
  to: Date
}

export interface OptionalRange {
  from?: Date
  to?: Date
}

export const rangePresetDef: { [key in PresetKey]: number[] | null } = {
  today: [0, 0],
  yesterday: [-1, -1],
  last7days: [-7, 0],
  last30days: [-30, 0],
  last90days: [-90, 0],
  last365days: [-365, 0],
  custom: null,
}

export interface RangePreset {
  offsets: number[] | null
  key: PresetKey
}

export const rangePresets: RangePreset[] = Object.keys(rangePresetDef).map((key) => {
  const k = key as PresetKey
  return { key: k, offsets: rangePresetDef[k] }
})
