import { defineStore, storeToRefs } from 'pinia'

import {
  getAccount,
  patchAccount,
  postAccountIdentityVerificationEmail,
  postAccountIdentityVerification,
  getExternalBankAccounts,
  deleteBankAccountUpdate,
} from '@/merchant/api/account'

import { manageStore } from '@/merchant/utils/storeListManager'
import { useUserStore } from '@/merchant/stores/user'

import type { Account, PatchAccountOptions, BankAccount } from '@/merchant/api/account'

interface PatchAccountParams extends Omit<PatchAccountOptions, 'companyId' | 'pointOfSaleId'> {}

export const useAccountStore = defineStore('account', () => {
  const user = useUserStore()
  const { apiPayload } = storeToRefs(user)
  const item = ref<Account | undefined>(undefined)
  const ibanList = ref<BankAccount[]>([])
  const loading = ref<boolean>(false)
  const loaded = computed(() => {
    return !!item?.value && !loading.value
  })

  const get = () =>
    manageStore({
      loading,
      request: () => getAccount(apiPayload.value),
      success: (result: Account) => {
        item.value = result
      },
    })

  const update = ({ account }: PatchAccountParams) =>
    manageStore({
      loading,
      request: () => patchAccount({ ...apiPayload.value, account }),
      success: (result: Account) => {
        item.value = result
      },
    })

  const verifyIdentity = (password: string) =>
    manageStore({
      loading,
      request: () => postAccountIdentityVerification({ ...apiPayload.value, password }),
      success: (result: Account) => {
        item.value = result
      },
    })

  const sendIdentityVerification = (email: string) =>
    manageStore({
      loading,
      request: () => postAccountIdentityVerificationEmail({ ...apiPayload.value, email }),
      success: (result: Account) => {
        item.value = result
      },
    })

  const getIbanList = () =>
    manageStore({
      loading,
      request: () => getExternalBankAccounts(apiPayload.value),
      success: (result: BankAccount[]) => {
        ibanList.value.splice(0, ibanList.value.length)
        result.forEach((obj) => ibanList.value.push(obj))
      },
    })

  const removeIbanUpdate = () =>
    manageStore({
      loading,
      request: () => deleteBankAccountUpdate(apiPayload.value),
      success: () => {
        get()
        getIbanList()
      },
    })

  const init = () => {
    if (!loaded.value) get()
  }

  const clear = () => {
    item.value = undefined
    loading.value = false
  }

  return {
    item,
    ibanList,
    loading,
    loaded,
    init,
    get,
    update,
    clear,
    verifyIdentity,
    sendIdentityVerification,
    getIbanList,
    removeIbanUpdate,
  }
})
