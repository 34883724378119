import { _useUserStore, type _User } from '@/merchant/stores/_user'
import { storeToRefs } from 'pinia'
import { captureException } from '@sentry/vue'
import { z } from 'zod'
import type { _Company, _PointOfSale } from '@/types/TrueTypes'

const authContextParser = z.object({
  user_id: z.string(),
  company_id: z.number(),
  point_of_sale_id: z.number(),
})

export const useAuthContext = () => {
  const userStore = _useUserStore()
  const { user, currentCompany, currentCompanyId, currentPointOfSale, currentPointOfSaleId } = storeToRefs(userStore)

  const result = authContextParser.safeParse({
    user_id: user.value?.uuid,
    company_id: currentCompanyId.value,
    point_of_sale_id: currentPointOfSaleId.value,
  })

  if (!result.success) {
    if (import.meta.env.DEV) {
      console.error('authContextParser', result.error.format())
    }

    captureException(new Error('authContextParser error'), (scope) => {
      scope.clear()
      scope.setContext('onboarding', {
        company: currentCompany.value,
        pos: currentPointOfSale.value,
        user_id: user.value?.uuid,
      })

      return scope
    })
  }

  return {
    user: user as ComputedRef<_User>,
    currentCompany: currentCompany as ComputedRef<_Company>,
    currentCompanyId: currentCompanyId as ComputedRef<number>,
    currentPointOfSale: currentPointOfSale as ComputedRef<_PointOfSale>,
    currentPointOfSaleId: currentPointOfSaleId as ComputedRef<number>,
  }
}
