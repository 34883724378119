import { createGtm } from '@gtm-support/vue-gtm'
import type { App } from 'vue'
import type { Router } from 'vue-router/auto'
import type { Router as DefaultVueRouter } from 'vue-router'

type GtmInstallOptions = {
  router: Router
}

const isDev = import.meta.env.DEV

export const install = (app: App, options: GtmInstallOptions): void => {
  const gtm = createGtm({
    id: 'GTM-TPX8K5H7',
    vueRouter: options.router as DefaultVueRouter,
    /** This is a legacy option to load the "dataLayer" in the window object
     * for now, we will keep it to true to avoid breaking changes
     * in the future, we will set it to false and all the
     * events will be tracked using the "trackEvent" method
     */
    loadScript: true,
    debug: isDev,
  })

  app.use(gtm, options)
}
