import { ref } from 'vue'

const testMode = ref(false)

export const useTestMode = () => {
  const storedTestMode = localStorage.getItem('settings.testMode')

  if (storedTestMode !== null && storedTestMode === 'true') {
    testMode.value = true
  }

  const toggleTestMode = () => {
    setTestMode(!testMode.value)
  }

  const setTestMode = (value: boolean) => {
    testMode.value = value

    localStorage.setItem('settings.testMode', testMode.value.toString())
  }

  return {
    testMode,
    toggleTestMode,
    setTestMode,
  }
}
