<script setup lang="ts">
import { type HTMLAttributes, computed, ref } from 'vue'
import { SwitchRoot, type SwitchRootProps, SwitchThumb, useForwardPropsEmits } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = defineProps<SwitchRootProps & { class?: HTMLAttributes['class']; modelValue?: boolean }>()

const emit = defineEmits<{
  (e: 'update:modelValue', payload: boolean): void
}>()

const delegatedProps = computed(() => {
  const { ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emit)

const switchRef = ref()
const handleChecked = (payload: boolean) => {
  switchRef.value?.focus()
  emit('update:modelValue', payload)
}
</script>

<template>
  <SwitchRoot
    v-bind="forwarded"
    @update:checked="handleChecked"
    :checked="modelValue"
    :class="[
      cn(
        'focus-within:ring-2 focus-within:ring-dp-600 focus-within:ring-offset-2 peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-green-500 data-[state=unchecked]:bg-input transition-all',
        forwarded.class,
      ),
      value ? '' : 'bg-dp-100',
    ]"
  >
    <SwitchThumb
      :class="
        cn(
          'pointer-events-none block h-5 w-5 rounded-full bg-grey-50 shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0',
        )
      "
    />
  </SwitchRoot>
</template>
