import { cva, type VariantProps } from 'class-variance-authority'

export { default as Input } from './Input.vue'

export const inputVariants = cva(
  'border-none w-full rounded-md bg-grey-100 px-3 py-2 ring-offset-background file:bg-transparent file:text-sm file:font-medium placeholder:text-grey-400 focus:outline-none focus:ring-2 focus:ring-dp-600 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 transition-all',
  {
    variants: {
      size: {
        default: 'px-3 py-2.5 text-base',
        sm: 'px-3 py-2 text-sm',
        lg: 'px-4 py-3.5 text-base',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
)

export type InputVariants = VariantProps<typeof inputVariants>
