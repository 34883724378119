<!-- eslint-disable max-len -->
<template>
  <svg width="83" height="92" viewBox="0 0 83 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M52.472 21.83c4.043 0 7.332 3.286 7.332 7.326v33.176c0 16.236-13.218 29.444-29.465 29.444C14.093 91.776.875 78.568.875 62.332c0-10.6 5.717-20.259 14.663-25.46v25.46c0 8.156 6.64 14.79 14.801 14.79 8.162 0 14.802-6.634 14.802-14.79V29.156c0-4.04 3.288-7.326 7.331-7.326zM52.661 0c16.246 0 29.464 13.208 29.464 29.444 0 10.6-5.717 20.258-14.663 25.46v-25.46c0-8.156-6.64-14.791-14.801-14.791-8.162 0-14.802 6.635-14.802 14.79V62.62c0 4.04-3.288 7.327-7.331 7.327-4.043 0-7.332-3.287-7.332-7.327V29.444C23.196 13.208 36.414 0 52.661 0z"
    />
  </svg>
</template>
