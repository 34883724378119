<script lang="ts" setup>
import { spaceThousands } from '@/core/utils/string'
import { isIcon } from '@/core/utils/icons-helper'

import detectAutofill from '../utils/detect-autofill'

import type { Icons } from '@/core/utils/icons'

interface InputProps {
  id?: string
  label?: string | null
  placeholder?: string
  modelValue?: string | number | null
  type?: string
  icon?: Icons | string
  currency?: string
  iconPosition?: 'right' | 'left'
  iconInteractive?: boolean
  autoFocus?: boolean
  required?: boolean
  autocomplete?: string
  valid?: boolean
  size?: 'base' | 'sm'
  disabled?: boolean
  locked?: boolean
}

defineOptions({
  inheritAttrs: false,
})

const attrs = useAttrs()
const filteredAttrs = computed(() =>
  Object.keys(attrs)
    .filter((name) => name !== 'class')
    .reduce((a, b) => ({ ...a, [b]: attrs[b] }), {}),
)

const props = withDefaults(defineProps<InputProps>(), {
  autoFocus: false,
  type: 'text',
  iconPosition: 'right',
  valid: undefined,
  size: 'base',
})

const computedType = computed(() => {
  let t = props.type
  switch (props.type) {
    case 'integer':
    case 'amount':
      t = 'tel'
      break
    case 'statement':
      t = 'text'
      break
  }
  return t
})

const iconSize = computed(() => {
  switch (props.size) {
    case 'base':
      return 'md'
    case 'sm':
      return 'base'
  }
})

const sideElement = computed(() => props.icon || props.currency)

const inputWrapper = ref(null)
const emit = defineEmits(['update:modelValue', 'click:icon', 'input', 'enter'])
const isFocused = ref(false)

const isLabelReduced = computed(() => !!props.modelValue || isFocused.value || props.type === 'date')

const inputElement = ref<HTMLInputElement | null>(null)
const { focused } = useFocus(inputElement, { initialValue: props.autoFocus })

const internalValue = computed<string | number | null | undefined>({
  get() {
    return props.modelValue
  },
  set(val) {
    let r = val
    switch (props.type) {
      case 'integer':
        if (!val) {
          r = '0'
          break
        }
        r = val.toString().replace(/[^0-9]/g, '')
        if (r.length > 1 && r.charAt(0) === '0') r = r.replace('0', '')
        break
      case 'statement':
        if (!val) {
          r = '0'
          break
        }
        r = spaceThousands(val.toString().replace(/[^0-9]/g, ''))
        if (r.length > 1 && r.charAt(0) === '0') r = r.replace('0', '')
        break
      case 'amount':
        if (!val) {
          r = '0'
          break
        }
        r = val
          .toString()
          .replace(/,/g, '.')
          .replace(/[^0-9.]/g, '')
        if (r.includes('.')) r = r.slice(0, r.indexOf('.') + 3)
        if (r.length > 1 && r.charAt(0) === '0' && r.charAt(1) !== '.') r = r.replace('0', '')
        if (r.length > 1 && r.charAt(0) === '.') r = `0${r}`
    }
    if (['integer', 'amount'].includes(props.type)) {
      if (attrs.min && r && +r < +attrs.min) r = attrs.min.toString()
      if (attrs.max && r && +r > +attrs.max) r = attrs.max.toString()
    }
    if (inputElement.value && typeof r === 'string') inputElement.value.value = r
    emit('update:modelValue', r)
  },
})

const focus = () => (focused.value = true)
defineExpose({
  focus,
  inputElement,
})

whenever(inputElement, (el) => {
  detectAutofill(el)
})
</script>

<template>
  <div
    ref="inputWrapper"
    class="relative"
    :class="[
      {
        'opacity-50 cursor-not-allowed': disabled,
      },
      attrs.class,
    ]"
  >
    <div
      v-if="label"
      class="left-0 absolute pointer-events-none duration-150 flex items-center w-full"
      :class="{
        'h-10': isLabelReduced,
        'h-full': !isLabelReduced,
        'px-4': !sideElement,
        'pl-14 pr-4': sideElement && iconPosition === 'left' && size === 'base',
        'pr-14 pl-4': sideElement && iconPosition === 'right' && size === 'base',
        'pl-10 pr-4': sideElement && iconPosition === 'left' && size === 'sm',
        'pr-10 pl-4': sideElement && iconPosition === 'right' && size === 'sm',
      }"
    >
      <div class="w-full overflow-hidden relative flex">
        <label
          :for="id"
          class="d-input-label text-darkgray transform antialiased whitespace-nowrap duration-150"
          :class="{ 'scale-75': isLabelReduced }"
        >
          {{ `${label} ${required ? '*' : ''}` }}
        </label>
      </div>
    </div>
    <input
      ref="inputElement"
      v-model="internalValue"
      :type="computedType"
      :disabled="disabled || locked"
      v-bind="filteredAttrs"
      :autocomplete="autocomplete"
      class="border-1 outline-none disabled:opacity-100 disabled:text-darkgray w-full appearance-none focus:border-focus bg-grey-50"
      :placeholder="!label || (label && isLabelReduced) ? placeholder : ''"
      :class="{
        'text-primary': !locked,
        'text-darkgray': locked,
        'h-14 rounded-xl': size === 'base',
        'h-10 rounded-full': size === 'sm',
        'pt-4': label,
        'px-4': !sideElement,
        'pl-14 pr-4': sideElement && iconPosition === 'left' && size === 'base',
        'pr-14 pl-4': sideElement && iconPosition === 'right' && size === 'base',
        'pl-10 pr-4': sideElement && iconPosition === 'left' && size === 'sm',
        'pr-10 pl-4': sideElement && iconPosition === 'right' && size === 'sm',
        'border-border': valid === undefined || locked,
        'border-success': valid === true && !locked,
        'border-danger': valid === false && !locked,
      }"
      @blur="isFocused = false"
      @focus="isFocused = true"
      @input="emit('input', $event)"
      @keydown.enter="emit('enter')"
    />
    <div
      v-if="sideElement"
      class="flex justify-center items-center absolute h-full top-0"
      :class="[
        {
          'text-darkgray': !iconInteractive,
          'cursor-pointer text-primary': iconInteractive,
          'right-0': iconPosition === 'right',
          'left-0': iconPosition === 'left',
          'w-14': size === 'base',
          'w-10': size === 'sm',
        },
        `icon--${iconPosition}`,
      ]"
      @click="iconInteractive ? emit('click:icon') : null"
    >
      <d-icon v-if="icon && isIcon(icon)" :icon="icon" :size="iconSize" />
      <span v-else-if="icon">{{ icon }}</span>
      <span v-else-if="currency" class="text-[1.25rem] leading-none">{{ currency }}</span>
    </div>
  </div>
</template>

<style lang="scss">
.d-input-label {
  will-change: transform;
  transform-origin: left top;
}
</style>
