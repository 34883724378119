import { useToast } from 'vue-toastification'
import Notification from './../components/DNotification.vue'

interface UseToastOptions {
  timeout: number
  message: string
  description: string
  type: 'success' | 'error' | 'warning' | 'info'
}

const toast = useToast()

export const showToast = (opts: Partial<UseToastOptions> = {}) => {
  const { timeout = 10000, message, description, type = 'info' } = opts
  toast(
    {
      component: Notification,
      props: {
        message,
        description,
        type,
      },
    },
    {
      timeout,
    },
  )
}

export const notify = showToast

export const clearToasts = () => toast.clear()
