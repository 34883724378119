import { defineStore } from 'pinia'

import type { Registration } from '@/merchant/api/registration'
import type { Company, MissingDocuments, PointOfSale } from '@/merchant/api/company'

export interface UserState extends Registration {
  activeCompanyId?: number | null
  activePointOfSaleId?: number | null
  activeCheckoutNumber?: string | null
}

const DEFAULT_STATE: UserState = {
  gender: null,
  nationalityCountryCode: null,
  email: '',
  unconfirmedEmail: null,
  confirmedAt: null,
  firstName: null,
  lastName: null,
  locale: 'fr',
  mobilePhoneNumber: null,
  mobilePhoneNumberCountry: null,
  mobilePhoneNumberConfirmedAt: null,
  companies: [],
  pointOfSales: [],
  pendingSponsorshipRequest: false,
  notificationAuthenticationSuccess: false,
  notificationMessageSent: false,
  notificationTransactionValidated: false,
  notificationTransactionValidatedMinimalAmount: null,
  twoFactorEnabled: false,
  avatarUrl: undefined,
  uuid: null,
  city: null,
  cityOfBirthCode: null,
  country: null,
  countryOfBirth: null,
  placeOfBirth: null,
  street1: null,
  street2: null,
  zipCode: null,
  notAvailableNotifySetAt: null,
  preferredPaymentMethods: {},
}

const activeCompanyId = useStorage<null | number>('session.activeCompanyId', -2)
const activePointOfSaleId = useStorage<null | number>('session.activePointOfSaleId', -2)
const activeCheckoutNumber = useStorage<null | string>('session.activeCheckoutNumber', null)

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    ...DEFAULT_STATE,
    activeCompanyId: activeCompanyId.value,
    activePointOfSaleId: activePointOfSaleId.value,
    activeCheckoutNumber: activeCheckoutNumber.value,
  }),
  getters: {
    isLoading: (state) => !state.email,
    isUserFetch: (state) => !!state.email,
    fullName: (state): string | undefined =>
      state.firstName && state.lastName ? `${state.firstName} ${state.lastName}` : undefined,
    currentUserEmail: (state) => state.unconfirmedEmail || state.email,
    isEmailConfirmed: (state) => !state.unconfirmedEmail,
    currentCompany: (state): Company | undefined =>
      state.companies.find((company: Company) => company.id === activeCompanyId.value),
    currentPointOfSale: (state): PointOfSale | undefined =>
      state.pointOfSales.find((p: PointOfSale) => p.id === activePointOfSaleId.value),
    currentCompanyId: () => Number(activeCompanyId.value || -1),
    currentPointOfSaleId: () => Number(activePointOfSaleId.value || -1),
    currentCompanySiren: (state) =>
      activeCompanyId.value ? state.companies.find((c) => c.id === activeCompanyId.value)?.siren : null,
    apiPayload: () => ({
      companyId: activeCompanyId.value || -1,
      pointOfSaleId: activePointOfSaleId.value || -1,
    }),
    currentCheckoutNumber: (): string | undefined => activeCheckoutNumber.value || undefined,
    sirens: (state) => new Set(state.companies.map((c) => c.siren)),
    sirets: (state) => new Set(state.pointOfSales.map((p) => p.siret)),
    isCurrentPointOfSaleReady: (state) => {
      const p = state.pointOfSales.find((p: PointOfSale) => p.id === activePointOfSaleId.value)
      return p ? p.completedSections.pointOfSale : false
    },
    isCurrentCompanyReady: (state) => {
      const p = state.pointOfSales.find((p: PointOfSale) => p.id === activePointOfSaleId.value)
      return p ? p.completedSections.company : false
    },
    isCurrentPointOfSaleBankReady: (state) => {
      const p = state.pointOfSales.find((p: PointOfSale) => p.id === activePointOfSaleId.value)
      return p ? p.completedSections.bank : false
    },
    isContractSigned: (state) => {
      const p = state.pointOfSales.find((p: PointOfSale) => p.id === activePointOfSaleId.value)
      return p ? p.completedSections.signature : false
    },
    isOnboardingCompleted: (state) => {
      const p = state.pointOfSales.find((p: PointOfSale) => p.id === activePointOfSaleId.value)
      return p ? !Object.values(p.completedSections).includes(false) : false
    },
    canSignContract: (state) => {
      const c = state.companies.find((company: Company) => company.id === activeCompanyId.value)
      return c ? !!c.permissions.canSignContract : false
    },
    isCompanySuspended: (state) => {
      const c = state.companies.find((company: Company) => company.id === activeCompanyId.value)
      return c ? !!c.suspendedAt : false
    },
    isPointOfSaleSuspended: (state) => {
      const p = state.pointOfSales.find((p: PointOfSale) => p.id === activePointOfSaleId.value)
      return p ? !!p.suspendedAt : false
    },
    isProductionModeEnabled: (state): boolean | undefined =>
      state.pointOfSales.find((p: PointOfSale) => p.id === activePointOfSaleId.value)?.productionModeEnabled,
    missingDocuments: (state): MissingDocuments | undefined =>
      state.pointOfSales.find((p: PointOfSale) => p.id === activePointOfSaleId.value)?.missingDocuments,
    anyMissingDocument: (state): boolean => {
      const pointOfSale = state.pointOfSales.find((p: PointOfSale) => p.id === activePointOfSaleId.value)

      if (!pointOfSale) return false

      const missingDocument = pointOfSale.missingDocuments

      if (!missingDocument) return false

      return missingDocument.substantiating || missingDocument.eaiCertificate || false
    },
    isMissingMerchantInformation: (state): boolean =>
      state.pointOfSales.find((p: PointOfSale) => p.id === activePointOfSaleId.value)?.missingMerchantInformation ||
      false,
    isSetNotAvailableNotifySetAt: (state): boolean => !!state.notAvailableNotifySetAt,
  },
  actions: {
    set(user: UserState, activeCompanyId: number, activePointOfSaleId: number) {
      // @ts-ignore - @TODO: fix this some day...
      this.$state = { ...user, activeCompanyId, activePointOfSaleId }
    },
    setActiveCompanyId(_activeCompanyId: number) {
      activeCompanyId.value = _activeCompanyId
    },
    setActivePointOfSaleId(_activePointOfSaleId: number) {
      activePointOfSaleId.value = _activePointOfSaleId
    },
    clear() {
      this.$state = {
        ...DEFAULT_STATE,
        activeCompanyId: -2,
        activePointOfSaleId: -2,
        activeCheckoutNumber: null,
      }
    },
    addCompany(company: Company) {
      this.companies.push(company)
    },
    addPointOfSale(pointOfSale: PointOfSale) {
      this.pointOfSales.push(pointOfSale)
    },
    updateCompany(company: Company) {
      const index = this.companies.findIndex((c) => c.id === company.id)
      this.companies.splice(index, 1, company)
    },
    updatePointOfSale(pointOfSale: PointOfSale) {
      const index = this.pointOfSales.findIndex((c) => c.id === pointOfSale.id)
      this.pointOfSales.splice(index, 1, pointOfSale)
    },
    updateAvatarUrl(avatarUrl?: string) {
      this.avatarUrl = avatarUrl
    },
    updateActiveCheckout(checkoutNumber?: string) {
      activeCheckoutNumber.value = checkoutNumber
    },
  },
})
