export const indempotent = (action) => {
  let called = false
  let result

  return (...args) => {
    if (called) {
      return result
    } else {
      result = action(...args)
      called = true

      return result
    }
  }
}

export const paintBoundingBox = (detectedCodes, ctx) => {
  for (const detectedCode of detectedCodes) {
    const { boundingBox: { x, y, width, height } } = detectedCode
    ctx.lineWidth = 2
    ctx.strokeStyle = '#007bff'
    ctx.strokeRect(x, y, width, height)
  }
}