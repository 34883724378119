<script setup lang="ts">
import type { Row } from '@/core/utils/component-types'

withDefaults(
  defineProps<{
    rows?: Row[]
    align?: 'between' | 'start' | 'end'
  }>(),
  {
    align: 'between'
  }
)
const emit = defineEmits(['click:icon'])
</script>

<template>
  <div class="flex flex-col gap-2">
    <template v-for="(row, index) in rows" :key="row.label">
      <hr v-if="index > 0" />
      <div class="flex gap-y-1 gap-x-4 flex-wrap" :class="{ 'flex-end': align === 'end' }">
        <p :class="{ 'flex-grow': align === 'between' }">{{ row.label }}</p>
        <div class="flex gap-2 items-center" :class="[row.color ? `text-${row.color}` : null]">
          <p class="font-semibold" v-html="row.value" />
          <template v-if="row.icon">
            <d-button
              v-if="row.iconClickable"
              :icon="row.icon"
              size="xs"
              color="dark"
              variant="text"
              @click.stop.prevent="emit('click:icon', row)"
            />
            <d-icon v-else :icon="row.icon" />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
