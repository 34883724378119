import { getRegistration, type Registration } from '../api/registration'
import { _useUserStore } from '../stores/_user'
import { useUserStore } from '../stores/user'

export const useRegistration = () => {
  const user = useUserStore()
  const _user = _useUserStore()

  const { activePointOfSaleId } = user

  const getUserInfos = async (token?: string) => {
    try {
      const res = await getRegistration(token)

      return res.registration
    } catch (err) {
      throw new Error('User is not authenticated')
    }
  }

  const setUser = (userInfos: Registration) => {
    const registration = userInfos
    const newActiveCompanyId = registration?.pointOfSales?.length > 0 ? registration.pointOfSales[0].companyId : -1
    const newActivePointOfSaleId = registration?.pointOfSales?.length > 0 ? registration.pointOfSales[0].id : -1
    const pointOfSaleId =
      activePointOfSaleId && registration?.pointOfSales?.some((pos) => pos.id === activePointOfSaleId)
        ? activePointOfSaleId
        : newActivePointOfSaleId
    const companyId =
      registration?.pointOfSales?.find((pos) => pointOfSaleId === pos.id)?.companyId || newActiveCompanyId
    user.set(registration, companyId, pointOfSaleId)
  }

  const loadUserInfos = async (token?: string) => {
    const userInfos = await getUserInfos(token)

    setUser(userInfos)
    _user.setUser(userInfos)

    return Promise.resolve()
  }

  const loadRegistration = async (token?: string) => {
    const res = await getRegistration(token)
    if (!res || !res.registration) {
      throw new Error('No registration found')
    }

    setUser(res.registration)
    _user.setUser(res.registration)

    return Promise.resolve()
  }

  return {
    loadRegistration,
    loadUserInfos,
    setUser,
  }
}
