<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'

import bus from '@/core/bus/eventBus'
import { showToast } from '@/core/hooks'

import { useUserStore } from '@/merchant/stores/user'
import { useRegistration } from '@/merchant/hooks/registration'
import { usePeopleStore } from '@/merchant/stores/people'
import { useGtm } from '@gtm-support/vue-gtm'

import { createContractSignature } from './../../api/contract'

import { RouterLink } from 'vue-router/auto'
import SignatureCard from './SignatureCard.vue'
import Button from '@/components/ui/button/Button.vue'

const { loadRegistration } = useRegistration()

const {
  isCurrentPointOfSaleReady,
  isCurrentCompanyReady,
  isContractSigned,
  isCurrentPointOfSaleBankReady,
  currentCompanyId,
  canSignContract,
  missingDocuments,
  currentCompany,
} = storeToRefs(useUserStore())
const showSignatureModale = ref(false)
const router = useRouter()
const people = usePeopleStore()
const { scoring } = storeToRefs(people)
const { mutate: signContract, isSuccess: isContractSignatureSuccess } = createContractSignature()
const gtm = useGtm()

const companyAnchor = computed(() => {
  const m = missingDocuments.value
  if (m?.eaiCertificate) return 'eai-fatca-card'
  if (m?.status || m?.substantiating) return 'substantiating-documents-section'
  if (!isCurrentCompanyReady.value) return 'beneficial-owners-card'
  return 'company-card'
})

const SignContract = () => {
  showSignatureModale.value = false
  bus.emit('prompt.open', {
    id: 'sign-contract',
    title: 'Signer le contrat',
    description:
      'Plus qu’à signer pour ouvrir votre compte DeluPay Pro !  Attention, l’ensemble des documents importés et les informations fournis ne seront plus modifiables.',
    submitText: `C'est bon, je signe`,
    cancelText: 'Je revérifie d’abord !',
    label: 'Nom de votre clé API',
  })
}

const onSave = () => {
  window.dataLayer!.push({
    event: 'validation_step_progress',
    step_validated: 'contract_signed_sent',
  })
  signContract({ companyId: currentCompanyId.value })
  loadRegistration()
}

const _trackSignContract = (value: 'agreed' | 'recheck') => {
  gtm?.trackEvent({
    event: 'pop_up_contract',
    category: 'merchant_onboarding',
    action: 'click',
    value,
  })
}

bus.on('prompt.close', ({ action, id }) => {
  if (id !== 'sign-contract') {
    return
  }

  if (action === 'cancel') {
    _trackSignContract('recheck')
  }

  if (action === 'confirm') {
    _trackSignContract('agreed')
    onSave()
  }
})

whenever(isContractSignatureSuccess, async () => {
  await nextTick()
  loadRegistration()
  showSignatureModale.value = false
  showToast({
    type: 'success',
    message: 'Signature du contrat confirmée, vous allez recevoir un mail contenant un exemplaire.',
  })
  // @TODO What happend if the scoring is not moderate or low ?
  if (['moderate', 'low'].includes(scoring.value.level as string)) {
    showToast({
      type: 'success',
      message:
        'Felicitation ! Votre nouveau compte DeluPay Pro sera finalisé dans 5mn et vous pourrez désactiver le mode test pour encaisser vos clients.',
    })
  }

  await nextTick(() => router.push({ name: 'merchant.dashboard' }))
})

type TrackVerificationValue = 'pos' | 'company' | 'banking_infos'
const _trackVerification = (value: TrackVerificationValue) => {
  const verifications = [
    isCurrentPointOfSaleReady.value,
    isCurrentCompanyReady.value,
    isCurrentPointOfSaleBankReady.value,
  ]

  const position = ['first', 'second', 'third'] as const

  // step position is the count of "true" in the verifications array
  const step = position[verifications.filter((v) => v).length]

  gtm?.trackEvent({
    event: `${step}_verification`,
    category: 'merchant_onboarding',
    action: 'click',
    value,
  })
}
</script>

<template>
  <TwCard class="flex flex-col gap-4">
    <d-message color="danger" icon="circle-info" :light="false">
      <p v-if="currentCompany && currentCompany.nifCountryCode?.code === 'US'">{{ $t('onboarding.info.us') }}</p>
      <p v-else>
        <i18n-t keypath="onboarding.info.default">
          <strong class="lowercase">{{ $t('onboarding.info.informations') }}</strong>
          <strong class="lowercase">{{ $t('onboarding.info.company') }}</strong>
        </i18n-t>
      </p>
    </d-message>

    <ul class="grid-cols-4 hidden lg:grid">
      <li class="text-center relative">
        <d-step-label :completed="isCurrentPointOfSaleReady" :index="1" />
        <hr class="absolute inset-0 top-1/2 z-0 left-1/2 -right-1/2 ml-5" />
      </li>
      <li class="text-center relative">
        <d-step-label :completed="isCurrentCompanyReady" :index="2" />
        <hr class="absolute inset-0 top-1/2 z-0 left-1/2 -right-1/2 ml-5" />
      </li>
      <li class="text-center relative">
        <d-step-label :completed="isCurrentPointOfSaleBankReady" :index="3" />
        <hr class="absolute inset-0 top-1/2 z-0 left-1/2 -right-1/2 ml-5" />
      </li>
      <li class="text-center relative">
        <d-step-label :completed="false" :index="4" />
      </li>
    </ul>
    <div class="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
      <div class="flex flex-col gap-4">
        <div class="flex items-center gap-2">
          <d-icon icon="shop" class="text-primary" />
          <div class="font-semibold leading-none">{{ $t('onboarding.shop.title') }}</div>
        </div>
        <div class="flex-1 text-sm">{{ $t('onboarding.shop.description') }}</div>
        <div
          v-if="isCurrentPointOfSaleReady"
          class="flex items-center justify-center font-semibold text-sm px-4 h-10 rounded-lg bg-green-100 text-green-600 bg-opacity-20 self-start"
        >
          {{ $t('onboarding.verified') }}
        </div>
        <RouterLink
          v-else
          :to="{ name: 'settings.point_of_sale.details', params: { edit: true }, hash: '#point-of-sale-card' }"
          @click="_trackVerification('pos')"
        >
          <Button>{{ $t('common.check') }}</Button>
        </RouterLink>
      </div>
      <div class="flex flex-col gap-4">
        <div class="flex items-center gap-2">
          <d-icon icon="firm" class="text-primary" />
          <div class="font-bold leading-none">{{ $t('onboarding.company.title') }}</div>
        </div>
        <div class="flex-1 text-sm">{{ $t('onboarding.company.description') }}</div>
        <div
          v-if="isCurrentCompanyReady"
          class="flex items-center justify-center font-semibold text-sm px-4 h-10 rounded-lg bg-green-100 text-green-600 bg-opacity-20 self-start"
        >
          {{ $t('onboarding.completed') }}
        </div>
        <RouterLink
          v-else
          :to="{
            name: 'settings.point_of_sale.company',
            hash: `#${companyAnchor}`,
          }"
          @click="_trackVerification('company')"
        >
          <Button>{{ $t('common.complete') }}</Button>
        </RouterLink>
      </div>
      <div class="flex flex-col gap-4">
        <div class="flex items-center gap-2">
          <d-icon icon="bank" class="text-primary" />
          <div class="font-bold leading-none">{{ $t('onboarding.bank_details.title') }}</div>
        </div>
        <div class="flex-1 text-sm">{{ $t('onboarding.bank_details.description') }}</div>
        <div
          v-if="isCurrentPointOfSaleBankReady"
          class="flex items-center justify-center font-semibold text-sm px-4 h-10 rounded-lg bg-green-100 text-green-600 bg-opacity-20 self-start"
        >
          {{ $t('common.completed') }}
        </div>
        <RouterLink
          v-else
          :to="{
            name: 'settings.point_of_sale.company',
            hash: '#bank-account-card',
            params: { edit: 'true' },
          }"
          @click="_trackVerification('banking_infos')"
        >
          <Button>{{ $t('common.complete') }}</Button>
        </RouterLink>
      </div>
      <div class="flex flex-col gap-4">
        <div class="flex items-center gap-2">
          <d-icon icon="signature" class="text-primary" />
          <div class="font-bold leading-none">{{ $t('onboarding.service_opening.title') }}</div>
        </div>
        <div class="flex-1 text-sm">
          <div class="grid gap-2">
            <p>{{ $t('onboarding.service_opening.description') }}</p>
            <p v-if="!isCurrentCompanyReady" class="italic">
              {{ $t('onboarding.service_opening.when_documents_ready') }}
            </p>
          </div>
        </div>
        <div
          v-if="isContractSigned"
          class="flex items-center justify-center font-semibold text-sm px-4 h-10 rounded-lg bg-green-100 text-green-600 bg-opacity-20 self-start"
        >
          {{ $t('common.completed') }}
        </div>
        <Button v-else class="self-start" :disabled="!canSignContract" @click="showSignatureModale = true">{{
          $t('onboarding.sign')
        }}</Button>
      </div>
    </div>
    <Modal v-model="showSignatureModale" size="2xl">
      <SignatureCard @sign="SignContract" @close="showSignatureModale = false" />
    </Modal>
  </TwCard>
</template>
