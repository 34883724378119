import '@/core/assets/styles/toastification.scss'

import Toast from 'vue-toastification'

const config = {
  timeout: 10000,
  icon: false
}

export { Toast, config }
