<template>
  <div class="flex flex-col gap-4">
    <p class="font-semibold">
      {{ $t('settings.company_sepa_mandate_modal.yes.sepa_mandate.mandate_authorizations.title') }}
    </p>
    <ul class="list-decimal pl-5">
      <li class="mb-2">{{ $t('settings.company_sepa_mandate_modal.yes.sepa_mandate.mandate_authorizations.1') }}</li>
      <li>{{ $t('settings.company_sepa_mandate_modal.yes.sepa_mandate.mandate_authorizations.2') }}</li>
    </ul>
    <hr />
    <p>{{ $t('settings.company_sepa_mandate_modal.yes.sepa_mandate.mandate_informations.1') }}</p>
    <p>{{ $t('settings.company_sepa_mandate_modal.yes.sepa_mandate.mandate_informations.2') }}</p>
  </div>
</template>
