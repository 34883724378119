<template>
<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1 7.91a3.744 3.744 0 0 1 7.033-1.79h5.828c.629 0 1.139.51 1.139 1.14v1.628c0 .27-.219.488-.488.488h-1.466v1.14c0 .27-.218.488-.488.488H10.93a.488.488 0 0 1-.488-.488v-1.14H8.19A3.745 3.745 0 0 1 1 7.911ZM4.744 6.61a1.302 1.302 0 1 0 0 2.604 1.302 1.302 0 0 0 0-2.604Z"
    fill="currentColor"
  />
</svg>
</template>
