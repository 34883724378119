<script lang="ts" setup>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

import { useRouter } from 'vue-router'

import { useAuth } from '@/core/composables/useAuth'
import { useTestMode } from '@/core/composables/useTestMode'

import type { Icons } from '@/core/utils/icons'
import { _useUserStore } from '@/merchant/stores/_user'

const { testMode, setTestMode } = useTestMode()

const _user = _useUserStore()

interface Button {
  text: string
  // @ts-ignore - This is any for now because i don't know how to type this @TODO refactor me
  route: any
  icon: Icons
}

const { logout: apiLogout } = useAuth()
const router = useRouter()
const { t } = useI18n()
const buttons: Button[] = [
  {
    text: 'navigation.user.security',
    route: 'me.account',
    icon: 'user',
  },
  {
    text: 'navigation.user.notifications',
    route: 'settings.notifications.index',
    icon: 'bell',
  },
  {
    text: 'navigation.user.sponsorship',
    route: 'settings.sponsorships.index',
    icon: 'sponsor',
  },
  {
    text: 'navigation.user.application',
    route: 'settings.application.index',
    icon: 'user',
  },
  {
    text: 'navigation.user.documents',
    route: 'point_of_sale.documents',
    icon: 'report',
  },
]

const logout = async () => {
  await apiLogout()
  window.dataLayer!.push({ event: 'sign_out' })
  router.push({ name: 'public.root' })
  // redirect to /login
  window.location.href = '/merchant/login'
}
</script>

<template>
  <Menu as="div" class="relative z-10">
    <div>
      <MenuButton
        class="inline-flex w-full justify-center rounded-md px-2 py-2 text-sm font-medium text-white hover:text-focus focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
      >
        <d-icon icon="user" class="-mr-1 h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-2xl bg-grey-50 shadow-lg ring-1 ring-black/5 focus:outline-none"
      >
        <div class="py-1 my-2">
          <MenuItem v-for="button of buttons" :key="button.route" v-slot="{ active }">
            <button
              @click="$router.push({ name: button.route })"
              :class="[
                active ? 'bg-dp-400 text-white' : 'text-gray-900',
                'group flex w-full items-center px-4 py-2 text-sm font-semibold',
              ]"
            >
              <d-icon :icon="button.icon" class="mr-4 flex-none" />
              {{ t(button.text) }}
            </button>
          </MenuItem>

          <MenuItem v-slot="{ active }">
            <button
              @click="logout"
              :class="[
                active ? 'bg-red-500 text-white' : 'text-red-600 bg-white',
                'group flex w-full items-center px-4 py-2 text-sm font-semibold justify-start',
              ]"
            >
              <d-icon icon="logout" class="mr-4" />
              {{ t('navigation.user.logout') }}
            </button>
          </MenuItem>
        </div>
        <div class="flex lg:hidden items-center justify-between p-4 text-black text-nowrap gap-2">
          <span class="font-semibold">
            Test mode {{ testMode ? $t('common.activated') : $t('common.desactivated') }}
          </span>
          <Switch
            @update:modelValue="setTestMode($event)"
            :disabled="!_user.isProductionModeEnabled"
            :modelValue="testMode"
            class="data-[state=checked]:bg-amber-600 data-[state=unchecked]:bg-grey-400 data-[state=checked]:focus-within:ring-amber-600"
          />
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
