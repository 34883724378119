import * as Sentry from '@sentry/vue'
import { env } from '~/env'
import pkg from '~/package.json'

import type { App } from 'vue'
import type { Router } from 'vue-router/auto'

type SentryInstallOptions = {
  router: Router
}

const getEnv = (host: string) => {
  if (host.includes('http://localhost')) return 'development'
  if (host.includes('https://preprod.app.delupay.com')) return 'pre-production'
  if (host.includes('https://edge.app.delupay.com')) return 'edge'
  if (host.includes('https://app.delupay.com')) return 'production'

  // Probably server side env so we return the mode
  return import.meta.env.MODE
}

export const install = (app: App, options: SentryInstallOptions): void => {
  if (getEnv(window.location.host) === 'development') return

  Sentry.init({
    app,
    release: pkg.version,
    environment: getEnv(window.location.host),
    dsn: env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({
        router: options.router,
        tracingOrigins: ['localhost', 'app.delupay.com', 'preprod.app.delupay.com', 'edge.app.delupay.com'],
      }),
      // Remove due to performance issues
      // Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
