<script setup lang="ts">
import { onMounted, ref } from 'vue'

const props = defineProps<{
  src: string
  alt?: string
}>()

const imgLoaded = ref(false)

onMounted(() => {
  if (!props.alt) {
    console.warn("Warn: it's a good practice to provide an alt attribute for images.")
  }
})
</script>

<template>
  <div class="transition-all">
    <div class="skeleton rounded-lg"></div>
    <img :src="src" :alt="alt ?? 'Image'" @load="imgLoaded = true" @error="imgLoaded = false" v-bind="$attrs" />
  </div>
</template>
