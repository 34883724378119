import { defineAsyncComponent } from 'vue'

import { useRoute } from 'vue-router'

import { isString } from '@/core/utils/validation'

import Default from '@/core/layouts/default.vue'
import DBackground from '../components/DBackground.vue'

// const Default = defineAsyncComponent(() => import('@/core/layouts/default.vue'))
const Simple = defineAsyncComponent(() => import('@/core/layouts/simple.vue'))
const Public = defineAsyncComponent(() => import('@/core/layouts/public.vue'))
const NotFound = defineAsyncComponent(() => import('@/core/layouts/not-found.vue'))

const layoutKeys = ['default', 'simple', 'public', 'not-found'] as const

type TLayoutKeys = (typeof layoutKeys)[number]
type TLayoutComponent = typeof Simple

export const useLayout = () => {
  const route = useRoute()

  const layoutsMapTable: Record<TLayoutKeys, TLayoutComponent> = {
    default: Default,
    simple: Simple,
    public: Public,
    'not-found': NotFound,
  }

  return {
    layout: computed(() => {
      const routeLayout = route.meta.layout

      // @TODO: here, i display the background by default if the route is not defined yet.
      //        but i should display the default layout instead.
      //        for some reason, the route is not defined yet when the app is loaded.
      //        i should investigate this.
      if (!routeLayout || !isString(routeLayout) || !layoutKeys.includes(routeLayout as TLayoutKeys)) {
        return DBackground
      }

      return layoutsMapTable[routeLayout as TLayoutKeys]
    }),
  }
}
