// Auto-generated file, to regenerate run : pnpm run icons
import IconAddUser from '../components/icons/IconAddUser.vue'
import IconApple from '../components/icons/IconApple.vue'
import IconArrDown from '../components/icons/IconArrDown.vue'
import IconBadge from '../components/icons/IconBadge.vue'
import IconBank from '../components/icons/IconBank.vue'
import IconBase from '../components/icons/IconBase.vue'
import IconBell from '../components/icons/IconBell.vue'
import IconBolt from '../components/icons/IconBolt.vue'
import IconCalendar from '../components/icons/IconCalendar.vue'
import IconChat from '../components/icons/IconChat.vue'
import IconCheck from '../components/icons/IconCheck.vue'
import IconCheckout from '../components/icons/IconCheckout.vue'
import IconChevronDown from '../components/icons/IconChevronDown.vue'
import IconChevronLeft from '../components/icons/IconChevronLeft.vue'
import IconChevronRight from '../components/icons/IconChevronRight.vue'
import IconCircleAdd from '../components/icons/IconCircleAdd.vue'
import IconCircleCheck from '../components/icons/IconCircleCheck.vue'
import IconCircleClose from '../components/icons/IconCircleClose.vue'
import IconCircleInfo from '../components/icons/IconCircleInfo.vue'
import IconCircleLoading from '../components/icons/IconCircleLoading.vue'
import IconCirclePause from '../components/icons/IconCirclePause.vue'
import IconCirclePerson from '../components/icons/IconCirclePerson.vue'
import IconCircleQuestion from '../components/icons/IconCircleQuestion.vue'
import IconCircleRefund from '../components/icons/IconCircleRefund.vue'
import IconCircleWarning from '../components/icons/IconCircleWarning.vue'
import IconClock from '../components/icons/IconClock.vue'
import IconClose from '../components/icons/IconClose.vue'
import IconCog from '../components/icons/IconCog.vue'
import IconCopy from '../components/icons/IconCopy.vue'
import IconDashboard from '../components/icons/IconDashboard.vue'
import IconDelupay from '../components/icons/IconDelupay.vue'
import IconDots from '../components/icons/IconDots.vue'
import IconDownload from '../components/icons/IconDownload.vue'
import IconEStore from '../components/icons/IconEStore.vue'
import IconEdit from '../components/icons/IconEdit.vue'
import IconEuro from '../components/icons/IconEuro.vue'
import IconExport from '../components/icons/IconExport.vue'
import IconEyeClosed from '../components/icons/IconEyeClosed.vue'
import IconEye from '../components/icons/IconEye.vue'
import IconFacebook from '../components/icons/IconFacebook.vue'
import IconFilterVertical from '../components/icons/IconFilterVertical.vue'
import IconFilter from '../components/icons/IconFilter.vue'
import IconFirm from '../components/icons/IconFirm.vue'
import IconIdentify from '../components/icons/IconIdentify.vue'
import IconInstagram from '../components/icons/IconInstagram.vue'
import IconInvoice from '../components/icons/IconInvoice.vue'
import IconKey from '../components/icons/IconKey.vue'
import IconLaptop from '../components/icons/IconLaptop.vue'
import IconLineCheckout from '../components/icons/IconLineCheckout.vue'
import IconLogout from '../components/icons/IconLogout.vue'
import IconMarker from '../components/icons/IconMarker.vue'
import IconMessage from '../components/icons/IconMessage.vue'
import IconNfc from '../components/icons/IconNfc.vue'
import IconPaperclip from '../components/icons/IconPaperclip.vue'
import IconPdf from '../components/icons/IconPdf.vue'
import IconPencil from '../components/icons/IconPencil.vue'
import IconPercentage from '../components/icons/IconPercentage.vue'
import IconPhone from '../components/icons/IconPhone.vue'
import IconPlanet from '../components/icons/IconPlanet.vue'
import IconPlus from '../components/icons/IconPlus.vue'
import IconQrCode from '../components/icons/IconQrCode.vue'
import IconReceipt from '../components/icons/IconReceipt.vue'
import IconRefresh from '../components/icons/IconRefresh.vue'
import IconRefund from '../components/icons/IconRefund.vue'
import IconReport from '../components/icons/IconReport.vue'
import IconScan from '../components/icons/IconScan.vue'
import IconSearch from '../components/icons/IconSearch.vue'
import IconSecurity from '../components/icons/IconSecurity.vue'
import IconSend from '../components/icons/IconSend.vue'
import IconSettings from '../components/icons/IconSettings.vue'
import IconShop from '../components/icons/IconShop.vue'
import IconSignature from '../components/icons/IconSignature.vue'
import IconSponsor from '../components/icons/IconSponsor.vue'
import IconStore from '../components/icons/IconStore.vue'
import IconTheme from '../components/icons/IconTheme.vue'
import IconTransaction from '../components/icons/IconTransaction.vue'
import IconTrash from '../components/icons/IconTrash.vue'
import IconTwitter from '../components/icons/IconTwitter.vue'
import IconUserGroup from '../components/icons/IconUserGroup.vue'
import IconUserPlus from '../components/icons/IconUserPlus.vue'
import IconUserShadow from '../components/icons/IconUserShadow.vue'
import IconUser from '../components/icons/IconUser.vue'
import IconUsers from '../components/icons/IconUsers.vue'
import IconX from '../components/icons/IconX.vue'

export type Icons =
  | 'add-user'
  | 'apple'
  | 'arr-down'
  | 'badge'
  | 'bank'
  | 'base'
  | 'bell'
  | 'bolt'
  | 'calendar'
  | 'chat'
  | 'check'
  | 'checkout'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'circle-add'
  | 'circle-check'
  | 'circle-close'
  | 'circle-info'
  | 'circle-loading'
  | 'circle-pause'
  | 'circle-person'
  | 'circle-question'
  | 'circle-refund'
  | 'circle-warning'
  | 'clock'
  | 'close'
  | 'cog'
  | 'copy'
  | 'dashboard'
  | 'delupay'
  | 'dots'
  | 'download'
  | 'e-store'
  | 'edit'
  | 'euro'
  | 'export'
  | 'eye-closed'
  | 'eye'
  | 'facebook'
  | 'filter-vertical'
  | 'filter'
  | 'firm'
  | 'identify'
  | 'instagram'
  | 'invoice'
  | 'key'
  | 'laptop'
  | 'line-checkout'
  | 'logout'
  | 'marker'
  | 'message'
  | 'nfc'
  | 'paperclip'
  | 'pdf'
  | 'pencil'
  | 'percentage'
  | 'phone'
  | 'planet'
  | 'plus'
  | 'qr-code'
  | 'receipt'
  | 'refresh'
  | 'refund'
  | 'report'
  | 'scan'
  | 'search'
  | 'security'
  | 'send'
  | 'settings'
  | 'shop'
  | 'signature'
  | 'sponsor'
  | 'store'
  | 'theme'
  | 'transaction'
  | 'trash'
  | 'twitter'
  | 'user-group'
  | 'user-plus'
  | 'user-shadow'
  | 'user'
  | 'users'
  | 'x'

export const iconList: { [key in Icons]: any } = {
  'add-user': IconAddUser,
  'apple': IconApple,
  'arr-down': IconArrDown,
  'badge': IconBadge,
  'bank': IconBank,
  'base': IconBase,
  'bell': IconBell,
  'bolt': IconBolt,
  'calendar': IconCalendar,
  'chat': IconChat,
  'check': IconCheck,
  'checkout': IconCheckout,
  'chevron-down': IconChevronDown,
  'chevron-left': IconChevronLeft,
  'chevron-right': IconChevronRight,
  'circle-add': IconCircleAdd,
  'circle-check': IconCircleCheck,
  'circle-close': IconCircleClose,
  'circle-info': IconCircleInfo,
  'circle-loading': IconCircleLoading,
  'circle-pause': IconCirclePause,
  'circle-person': IconCirclePerson,
  'circle-question': IconCircleQuestion,
  'circle-refund': IconCircleRefund,
  'circle-warning': IconCircleWarning,
  'clock': IconClock,
  'close': IconClose,
  'cog': IconCog,
  'copy': IconCopy,
  'dashboard': IconDashboard,
  'delupay': IconDelupay,
  'dots': IconDots,
  'download': IconDownload,
  'e-store': IconEStore,
  'edit': IconEdit,
  'euro': IconEuro,
  'export': IconExport,
  'eye-closed': IconEyeClosed,
  'eye': IconEye,
  'facebook': IconFacebook,
  'filter-vertical': IconFilterVertical,
  'filter': IconFilter,
  'firm': IconFirm,
  'identify': IconIdentify,
  'instagram': IconInstagram,
  'invoice': IconInvoice,
  'key': IconKey,
  'laptop': IconLaptop,
  'line-checkout': IconLineCheckout,
  'logout': IconLogout,
  'marker': IconMarker,
  'message': IconMessage,
  'nfc': IconNfc,
  'paperclip': IconPaperclip,
  'pdf': IconPdf,
  'pencil': IconPencil,
  'percentage': IconPercentage,
  'phone': IconPhone,
  'planet': IconPlanet,
  'plus': IconPlus,
  'qr-code': IconQrCode,
  'receipt': IconReceipt,
  'refresh': IconRefresh,
  'refund': IconRefund,
  'report': IconReport,
  'scan': IconScan,
  'search': IconSearch,
  'security': IconSecurity,
  'send': IconSend,
  'settings': IconSettings,
  'shop': IconShop,
  'signature': IconSignature,
  'sponsor': IconSponsor,
  'store': IconStore,
  'theme': IconTheme,
  'transaction': IconTransaction,
  'trash': IconTrash,
  'twitter': IconTwitter,
  'user-group': IconUserGroup,
  'user-plus': IconUserPlus,
  'user-shadow': IconUserShadow,
  'user': IconUser,
  'users': IconUsers,
  'x': IconX,
}
