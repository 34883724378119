<script setup lang="ts" generic="T">
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { type SelectButtonVariants, selectButtonVariants } from '.'
import type { HTMLAttributes } from 'vue'
import type { PrimitiveProps } from 'radix-vue'
import { useModel } from 'vue'

interface SelectOption {
  label: string
  value: string | number | boolean | object | null | undefined
}

interface Props extends PrimitiveProps {
  placeholder?: string
  options: SelectOption[]
  size?: SelectButtonVariants['size']
  class?: HTMLAttributes['class']
  modelValue?: SelectOption['value']
  disabled?: boolean
  openTop?: boolean
  loading?: boolean
}

defineEmits<{
  (e: 'update:modelValue', payload: string): void
}>()

const props = withDefaults(defineProps<Props>(), {
  placeholder: 'Select an option',
  openTop: false,
})

const model = useModel(props, 'modelValue')
</script>

<template>
  <div class="block sm:hidden relative">
    <select
      :class="[selectButtonVariants({ size }), 'border-none relative bg-none', !model ? 'text-grey-500' : '']"
      v-model="model"
      :placeholder="placeholder"
      :disabled="disabled"
    >
      <option class="text-grey-400" :value="undefined" disabled selected>{{ placeholder }}</option>
      <option v-for="(option, idx) of options" :key="idx" :value="option.value">{{ option.label }}</option>
    </select>
    <div class="pointer-events-none absolute inset-y-0 right-2 flex items-center px-2 text-gray-700">
      <Icon icon="chevron-down" class="h-4 w-4 text-gray-400" aria-hidden="true" />
    </div>
  </div>
  <Listbox v-slot="{ open }" class="hidden sm:block" v-model="model" :disabled="disabled">
    <div class="relative mt-1">
      <ListboxButton v-if="model && !loading" :class="selectButtonVariants({ size })">
        <span class="block truncate">{{ options.find((option) => option.value === modelValue)?.label ?? '' }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
          <Icon
            icon="chevron-down"
            class="h-4 w-4 text-gray-400"
            :class="[openTop ? 'rotate-180' : '', open ? 'rotate-180' : '']"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>
      <ListboxButton v-else :class="selectButtonVariants({ size })">
        <span class="block truncate text-grey-400">{{ placeholder }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
          <Icon
            icon="chevron-down"
            class="h-4 w-4 text-gray-400"
            :class="openTop ? 'rotate-180' : ''"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          v-if="!loading"
          class="absolute mt-1 mb-1 max-h-60 w-full overflow-auto rounded-md bg-grey-100 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none"
          :class="openTop ? 'bottom-full' : 'top-full'"
        >
          <ListboxOption
            v-slot="{ active, selected }"
            v-for="option in options"
            :key="option.value as PropertyKey"
            :value="option.value"
            as="template"
          >
            <li
              :class="[
                active ? 'bg-dp-100 text-dp-900' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-10 pr-4',
              ]"
            >
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ option.label }}</span>
              <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-dp-600">
                <Icon class="h-5 w-5" icon="check" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
