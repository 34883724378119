import client from '@/core/api/client'

interface FeatureResponse {
  active: never[]
  inactive: never[]
}

export const getFeatures = async () => {
  const res = await client.get<FeatureResponse>('/features')

  return res.data
}
