<script lang="ts" setup>
import { useUserStore } from '@/merchant/stores/user'

import { useI18n } from 'vue-i18n'
import { useDayjs } from '@/core/composables/useDayjs'

import TransactionStateTag from './TransactionStateTag.vue'

import type { Transaction } from '@/merchant/api/transaction'

const props = defineProps<{
  transaction: Transaction
}>()

defineEmits(['close:modale'])

const { t } = useI18n()
const { dayjs } = useDayjs()

const user = useUserStore()

const rows = computed(() => {
  if (!props.transaction) return []
  return [
    {
      label: t('transaction.preview_card.list.date'),
      value: dayjs(props.transaction.createdAt).fromNow(),
    },
    {
      label: t('transaction.preview_card.list.hour'),
      value: dayjs(props.transaction.createdAt).format('HH:mm:ss'),
    },
    {
      label: t('transaction.preview_card.list.point_of_sale'),
      value: user.currentPointOfSale?.title,
    },
    {
      label: t('transaction.preview_card.list.checkout'),
      value: props.transaction.checkout.title,
    },
    {
      label: t('transaction.preview_card.list.customer'),
      value: props.transaction.customer?.name || '-',
    },
    {
      label: t('transaction.preview_card.list.reference'),
      value: props.transaction.reference,
    },
  ]
})
</script>

<template>
  <div class="flex flex-col items-center gap-4">
    <p class="lowercase first-letter:uppercase text-2xl font-semibold">
      {{ $t('transaction.preview_card.transaction') + ' ' + $t(`transaction.activity_state.${transaction.state.key}`) }}
    </p>

    <div class="flex flex-col items-center">
      <span v-if="transaction.target">{{ transaction.target.title }}</span>
      <p class="text-4xl leading-normal">
        {{ transaction.refund ? transaction.refund?.refundableAmount?.localized : transaction.amount.localized }}
      </p>
      <p v-if="transaction.refund" class="text-xl text-danger leading-snug whitespace-nowrap">
        {{ `-${transaction.refund.amount.localized}` }}
      </p>
    </div>
    <div class="flex gap-2">
      <TransactionStateTag
        v-if="transaction.refund && transaction.refund.partial"
        :custom-state="{
          icon: 'circle-refund',
          color: 'bg-info text-white',
          text: $t('transaction.activity_state.partially_refunded'),
        }"
      />
      <TransactionStateTag v-else :state="transaction.state" />

      <TransactionStateTag
        v-if="!transaction.refund && transaction.dispute"
        :custom-state="{
          icon: 'circle-refund',
          color: 'bg-warning text-white',
          text: $t('transaction.activity_state.disputed'),
        }"
      />
    </div>

    <d-rows :rows="rows" class="self-stretch" />
  </div>
</template>
