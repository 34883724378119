import { createRouter, createWebHistory } from 'vue-router/auto'

import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export type BeforeEachMiddleware = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => void
export type AfterEachMiddleware = (to: RouteLocationNormalized, from: RouteLocationNormalized) => void

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: (to) => (to.hash ? { el: to.hash } : { top: 0 }),
})

export default router
