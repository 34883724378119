<script lang="ts" setup>
import bus from '@/core/bus/eventBus'
import { emailIsValid } from '@/core/utils/string'

import DForgetPassword from '@/core/components/DForgetPassword.vue'

import type { PromptOpenEvent } from '@/core/bus/eventBus'
import Button from '@/components/ui/button/Button.vue'

const { t } = useI18n()
const show = ref(false)
const instructions = ref<string | undefined>(undefined)
const type = ref<string | undefined>()
const input = ref()
const id = ref<string | undefined>(undefined)
const title = ref<string | undefined>(undefined)
const submitText = ref<string | undefined>(undefined)
const cancelText = ref<string | undefined | null>(undefined)
const placeholder = ref<string | undefined>(undefined)
const submitType = ref<string | undefined>(undefined)
const required = ref<boolean | undefined>(undefined)
const disabledCheck = ref<((value: string) => boolean) | undefined>(undefined)
const format = ref<((value: string) => string) | undefined>(undefined)
const label = ref<string | undefined>(undefined)
const canClose = ref(false)

const isEmailValid = computed<undefined | boolean>(() => {
  if (type.value !== 'email' || !input.value?.length) return
  return emailIsValid(input.value)
})

bus.on('prompt.open', (opts: Partial<PromptOpenEvent> = {}) => {
  const {
    description,
    inputType,
    id: i,
    title: t,
    submitText: submit,
    cancelText: c,
    inputValue = undefined,
    placeholder: p,
    submitType: s,
    required: r,
    disabledCheck: d,
    format: f,
    label: l,
    canClose: cc,
  } = opts
  id.value = i
  title.value = t
  instructions.value = description
  submitText.value = submit
  type.value = inputType
  show.value = true
  input.value = inputValue
  placeholder.value = p
  submitType.value = s
  required.value = r
  disabledCheck.value = d
  format.value = f
  label.value = l
  cancelText.value = c
  canClose.value = cc || false
})

const reset = () => {
  input.value = undefined
  title.value = undefined
  submitText.value = undefined
  cancelText.value = undefined
  id.value = undefined
  instructions.value = ''
  disabledCheck.value = undefined
  label.value = undefined
  canClose.value = false
}

const cancel = () => {
  bus.emit('prompt.close', { value: null, id: id.value as string, action: 'cancel' })
  show.value = false

  // Add some time between the close and the reset to avoid that the
  // component is unmounted before the animation is finished
  setTimeout(() => reset(), 300)
}

const confirm = () => {
  show.value = false
  bus.emit('prompt.close', {
    value: format.value && typeof input.value === 'string' ? format.value(input.value) : input.value,
    id: id.value as string,
    action: 'confirm',
  })

  // Same as cancel
  setTimeout(() => reset(), 300)
}

const isSubmitDisabled = computed(() => {
  return (
    (required.value && (!input.value || (input.value && input.value.length < 1))) ||
    (disabledCheck.value && disabledCheck.value(input.value || ''))
  )
})
</script>

<template>
  <Modal v-model="show" :no-auto-close="!canClose" size="2xl" :can-close="canClose">
    <div class="flex flex-col gap-4">
      <p v-if="title" class="text-center font-semibold text-2xl">{{ title }}</p>
      <p v-if="instructions" class="max-w-lg text-center text-darkgray">{{ instructions }}</p>
      <template v-if="type">
        <d-password-input
          v-if="type === 'password'"
          v-model="input"
          :autocomplete="type === 'password' ? 'current-password' : undefined"
          :autofocus="true"
          :label="label"
        />
        <textarea
          v-else-if="type === 'textarea'"
          v-model="input"
          class="w-full bg-gray-200 rounded-xl outline-none border border-transparent focus:border-primary p-5 min-h-34"
          :autofocus="true"
          :placeholder="placeholder"
          :label="label"
        />
        <d-input
          v-else
          v-model="input"
          :auto-focus="true"
          :type="type"
          :placeholder="placeholder"
          :label="label"
          :valid="isEmailValid"
        />
      </template>
      <d-forget-password v-if="type === 'password'" />
      <div v-if="isEmailValid === false" class="flex items-center gap-2 text-danger -mt-2">
        <d-icon icon="circle-warning" />
        <p class="text-sm">
          {{ t('registration.create.email.error') }}
        </p>
      </div>
      <div class="grid gap-x-4" :class="{ 'xs:grid-cols-2': cancelText !== null }">
        <Button v-if="cancelText !== null" size="lg" variant="none" @click="cancel">
          {{ cancelText || $t('common.cancel') }}
        </Button>
        <Button size="lg" :disabled="isSubmitDisabled" @click="confirm">
          {{ submitText || $t('common.confirm') }}
        </Button>
      </div>
    </div>
  </Modal>
</template>
