<template>
<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.5 1.5a1 1 0 0 0-1-1H2.472c-.524 0-1 .448-1 1v2c0 .553.476 1 1 1h2.5v1H3.22a2 2 0 0 0-1.978 1.7l-.708 4.678a2.954 2.954 0 0 0-.034.447V14.5a2 2 0 0 0 2 2h12c1.103 0 2-.897 2-2v-2.175c0-.15-.012-.3-.034-.447l-.71-4.678a2.004 2.004 0 0 0-2.003-1.7H7v-1h2.5c.553 0 1-.447 1-1v-2ZM3 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm11 12c.275 0 .5-.225.5-.5s-.225-.5-.5-.5H3a.5.5 0 0 0 0 1h11ZM3.25 8a.75.75 0 1 1 1.5 0 .75.75 0 1 1-1.5 0ZM7 8.75c.416 0 .75-.334.75-.75A.748.748 0 0 0 7 7.25a.748.748 0 0 0-.75.75c0 .416.334.75.75.75ZM4.75 10.5c0-.416.334-.75.75-.75s.75.334.75.75-.334.75-.75.75a.748.748 0 0 1-.75-.75ZM10 8.75c.416 0 .75-.334.75-.75a.748.748 0 0 0-.75-.75.748.748 0 0 0-.75.75c0 .416.334.75.75.75ZM7.75 10.5c0-.416.334-.75.75-.75s.75.334.75.75-.334.75-.75.75a.748.748 0 0 1-.75-.75ZM13 8.75c.416 0 .75-.334.75-.75a.748.748 0 0 0-.75-.75.748.748 0 0 0-.75.75c0 .416.334.75.75.75Zm-2.25 1.75c0-.416.334-.75.75-.75s.75.334.75.75-.334.75-.75.75a.748.748 0 0 1-.75-.75Z"
    fill="currentColor"
  />
</svg>
</template>
