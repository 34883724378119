<script setup lang="ts">
import { registerSW } from 'virtual:pwa-register'

defineEmits(['close', 'update'])

const showUpdateSW = ref(false)
const updateSW = registerSW({
  onNeedRefresh() {
    showUpdateSW.value = true
  },
})

const updateSWNow = () => {
  updateSW()
  showUpdateSW.value = false
}
</script>

<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-end sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="showUpdateSW"
          class="pointer-events-auto w-full max-w-sm rounded-lg bg-grey-50 shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="ml-3 w-0 flex-1">
                <p class="text-sm font-medium text-gray-900">{{ $t('sw_new_version.title') }}</p>
                <p class="mt-1 text-sm text-gray-500">{{ $t('sw_new_version.description') }}</p>
                <div class="mt-4 flex">
                  <button
                    type="button"
                    class="inline-flex items-center rounded-md bg-primary px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    @click="updateSWNow"
                  >
                    {{ $t('common.reload') }}
                  </button>
                  <button
                    type="button"
                    class="ml-3 inline-flex items-center rounded-md bg-grey-50 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    @click="showUpdateSW = false"
                  >
                    {{ $t('common.close') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
