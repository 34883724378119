import type { Ref, ComputedRef } from 'vue'
import { defineStore } from 'pinia'

import { useUserStore } from './user'
import { useAccountStore } from '@/merchant/stores/account'
import { usePeopleStore } from '@/merchant/stores/people'
import { useSystemNotificationStore } from '@/merchant/stores/systemNotifications'
import { useDocumentStore } from '@/merchant/stores/documents'

import { _deleteToken } from '@/core/composables/useAuth'

interface AuthStore {
  isDev: boolean
  token: Ref<string | null> | null
  expiresAt: Ref<number | null> | null
  loggedIn: ComputedRef<boolean>
  clear: () => void
  logout: () => void
}

export const useAuthStore = defineStore('auth', (): AuthStore => {
  const user = useUserStore()
  const account = useAccountStore()
  const people = usePeopleStore()
  const systemNotification = useSystemNotificationStore()
  const documents = useDocumentStore()
  const isDev = import.meta.env.DEV
  const token = useStorage<null | string>('bearer_token', null)
  const expiresAt = useStorage<null | number>('expires_at', 0)

  const clear = () => {
    token.value = null
    expiresAt.value = 0

    _deleteToken()
  }

  const logout = () => {
    user.clear()
    account.clear()
    people.clear()
    systemNotification.clear()
    documents.clear()

    clear()
  }

  const loggedIn = computed((): boolean => {
    return !!user.uuid
  })

  return {
    token,
    expiresAt,
    loggedIn,
    isDev,
    clear,
    logout,
  }
})
