<template>
  <svg viewBox="0 0 100 100" class="text-primary max-w-full fixed left-0 top-0 svg-circle">
    <circle r="75" cx="0" cy="0" stroke-width="50" stroke="currentcolor" fill="transparent"></circle>
  </svg>
  <svg viewBox="0 0 100 100" class="text-primary max-w-full fixed right-0 bottom-0 svg-circle">
    <circle
      v-for="key in 5"
      :key="key"
      :r="47.5 + 10 * key"
      cx="100"
      cy="100"
      stroke-width="5"
      stroke="currentcolor"
      fill="transparent"
    ></circle>
  </svg>
</template>

<style lang="scss" scoped>
.svg-circle {
  width: max(50vw, 30rem);
}
</style>
