import { cva, type VariantProps } from 'class-variance-authority'

export { default as Select } from './Select.vue'

export const selectButtonVariants = cva(
  'relative w-full cursor-default rounded-lg bg-grey-100 text-grey-900 text-left focus:outline-none focus:ring-2 focus:ring-dp-600 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 transition-all',
  {
    variants: {
      size: {
        default: 'pl-3 pr-10 py-3 text-base',
        sm: 'pl-2 pr-8 py-2 text-sm',
        lg: 'pl-4 pr-12 py-4 text-base',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
)

export type SelectButtonVariants = VariantProps<typeof selectButtonVariants>
