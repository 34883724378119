<script lang="ts" setup>
import {
  getCountryCallingCode,
  parsePhoneNumber,
  type CountryCode as PhoneCountryCode,
  isPossiblePhoneNumber,
  getCountries,
} from 'libphonenumber-js'

import { getEmojiFlagByAlphaCode, searchCountries } from '@/core/utils/countries'

import type { CountryCode } from '@/core/utils/countries'

withDefaults(
  defineProps<{
    autocomplete?: string
    autoFocus?: boolean
    required?: boolean
    locked?: boolean
  }>(),
  {
    autocomplete: 'off',
    autoFocus: true,
  },
)

const { t } = useI18n()
const phoneNumberTarget = ref()
const countrySelector = ref()
const showCountryList = ref(false)
const alpha2 = ref<CountryCode>('FR')
const inputNumber = ref<string | undefined>(undefined)
const { search, countries } = searchCountries({ fill: true })
const callingCodes: { [key: string]: string } = getCountries()
  .map((key) => ({ key, code: getCountryCallingCode(key as PhoneCountryCode) }))
  .reduce((a, b) => ({ ...a, [b.code]: b.key }), {})

const filteredCallingCodes = computed(() => {
  if (!/^[\d+]+$/.test(search.value)) return []
  return Object.keys(callingCodes)
    .filter((code) => code.toString().includes(search.value.replace(/\+/g, '')))
    .map((code) => callingCodes[code]) as CountryCode[]
})

const updateSelectedCountryCode = (code: CountryCode) => {
  alpha2.value = code
  search.value = ''
  showCountryList.value = false
  phoneNumberTarget.value.focus()
}

onClickOutside(countrySelector, () => {
  showCountryList.value = false
})

const getCountryEmoji = (code: CountryCode | null) => (code ? getEmojiFlagByAlphaCode(code) : null)

const parsedNumber = computed(() => {
  if (!inputNumber.value || !alpha2.value) return
  if (!isPossiblePhoneNumber(inputNumber.value, alpha2.value as PhoneCountryCode)) return
  return parsePhoneNumber(inputNumber.value, alpha2.value as PhoneCountryCode)
})

const isValid = computed(() => parsedNumber.value?.isPossible() || false)

const phoneNumberFormatted = computed(() => parsedNumber.value?.number)

const emit = defineEmits(['update', 'enter'])

const preFill = ({ code, number }: { code: CountryCode; number: string }) => {
  alpha2.value = code
  inputNumber.value = number
}

watch(phoneNumberFormatted, () => {
  emit('update', phoneNumberFormatted.value)
})

defineExpose({ isValid, phoneNumberFormatted, alpha2, preFill })
</script>

<template>
  <div class="flex items-center gap-2 relative">
    <div ref="countrySelector">
      <div
        class="flex h-14 rounded-xl items-center gap-1 cursor-pointer px-4 bg-grey-50 text-dark border-2"
        :class="{
          'border-focus': showCountryList,
          'border-border': !showCountryList,
          'bg-grey-50': showCountryList,
          'pointer-events-none': locked,
        }"
        @click.stop="showCountryList = !showCountryList"
      >
        <span>{{ getEmojiFlagByAlphaCode(alpha2) }}</span>
        <span>{{ `+${getCountryCallingCode(alpha2 as PhoneCountryCode)}` }}</span>
      </div>
      <div
        v-if="showCountryList"
        class="absolute max-h-60 overflow-hidden z-10 overflow-y-auto w-full flex flex-col bg-grey-50 rounded-xl shadow-xl mt-2"
      >
        <div class="p-2">
          <d-input
            id="searchCountryInput"
            v-model="search"
            type="text"
            :auto-focus="true"
            :placeholder="t('phone.input.country.search.placeholder')"
            size="sm"
          />
        </div>
        <div class="overflow-y-auto flex-grow flex flex-col">
          <div
            v-for="code in [...countries, ...filteredCallingCodes]"
            :key="code"
            class="px-3 py-1 min-h-10 flex items-center gap-2"
            :class="{
              'order-2 hover:bg-hover text-dark cursor-pointer': code !== alpha2,
              'order-1 bg-primary text-white': code === alpha2,
            }"
            @click="updateSelectedCountryCode(code)"
          >
            <span>{{ getCountryEmoji(code) }}</span>
            <span>{{ t(`countries.${code}`) }}</span>
            <span v-if="filteredCallingCodes.length">(+{{ getCountryCallingCode(code as PhoneCountryCode) }})</span>
          </div>
        </div>
      </div>
    </div>
    <d-input
      id="phoneNumberInput"
      ref="phoneNumberTarget"
      v-model="inputNumber"
      class="flex-grow"
      :label="t('phone.input.label')"
      :placeholder="t('phone.input.placeholder')"
      :auto-focus="autoFocus"
      :autocomplete="autocomplete"
      :valid="inputNumber?.length && !locked ? isValid : undefined"
      :required="required"
      :locked="locked"
      @enter="emit('enter')"
    />
  </div>
</template>
