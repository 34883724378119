import { featureKeys, useFeatureFlagsStore } from '@/merchant/stores/featureFlags'
import { useAuthStore } from '@/merchant/stores/auth'

import type { NavigationGuard } from 'vue-router/auto'

export const featuresMiddleware: NavigationGuard = async (to, _from, next) => {
  const { loggedIn } = useAuthStore()
  const { init, can, isLoaded } = useFeatureFlagsStore()

  if (to.meta.onlyPublic || !to.meta.requiresAuth) {
    return next()
  }

  if (loggedIn && to.meta.requiresFeature) {
    if (!isLoaded) await init()

    const featureKey = to.meta.requiresFeature as (typeof featureKeys)[number]

    if (!can(featureKey)) {
      return next({ name: 'merchant.dashboard' })
    }
  }

  next()
}
