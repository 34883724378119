import { defineStore, storeToRefs } from 'pinia'

import { useUserStore } from './user'
import { manageStore } from '@/merchant/utils/storeListManager'
import { fetchPeople, patchPerson, createPerson, verifyBeneficialOwner } from '../api/people'

import type { Ref, ComputedRef } from 'vue'
import type { ListStore } from '@/merchant/utils/storeListManager'
import type {
  Scoring,
  VerifyBeneficialOwnerOptions,
  CreatePersonOptions,
  FetchPeopleResponseData,
  PatchPersonOptions,
  Person,
} from '@/merchant/api/people'

interface UpdatePersonParams extends Omit<PatchPersonOptions, 'companyId'> {}
interface AddPersonParams extends Omit<CreatePersonOptions, 'companyId'> {}
interface SendVerificationParams extends Omit<VerifyBeneficialOwnerOptions, 'companyId'> {}

export interface PeopleStore extends ListStore {
  items: Ref<{ [id: string]: Person }>
  loaded: ComputedRef<boolean>
  get: () => Promise<boolean>
  update: (opt: UpdatePersonParams) => Promise<boolean>
  add: (opt: AddPersonParams) => Promise<boolean>
  sendVerification: (opt: SendVerificationParams) => Promise<boolean>
  clear: () => void
  missingInformation: Ref<boolean>
  scoring: Partial<Scoring>
}

export const usePeopleStore = defineStore('people', (): PeopleStore => {
  const user = useUserStore()
  const { currentCompanyId } = storeToRefs(user)
  const items = ref<{ [id: string]: Person }>({})
  const list = ref<string[]>([])
  const loading = ref<boolean>(false)
  const missingInformation = ref(false)
  const scoring = reactive<Partial<Scoring>>({})
  const loaded = computed(() => !!list?.value?.length && loading.value === false)

  const populate = (response: FetchPeopleResponseData) => {
    list.value.splice(0, list.value.length)
    response.companyPeople.forEach((p) => {
      items.value[p.id.toString()] = p
      list.value.push(p.id.toString())
    })
    missingInformation.value = response.missingInformation
    if (response.scoring) {
      scoring.level = response.scoring.level
      scoring.alert = response.scoring.alert
    }
  }

  const get = () =>
    manageStore({
      loading,
      request: () => fetchPeople(user.currentCompanyId),
      success: populate,
    })

  const add = async ({ person }: AddPersonParams) =>
    manageStore({
      loading,
      request: () => createPerson({ companyId: currentCompanyId.value, person }),
      success: populate,
    })

  const update = ({ personId, person }: UpdatePersonParams) =>
    manageStore({
      loading,
      request: () => patchPerson({ companyId: currentCompanyId.value, personId, person }),
      success: populate,
    })

  const sendVerification = ({ personId, email }: SendVerificationParams) =>
    manageStore({
      loading,
      request: () => verifyBeneficialOwner({ companyId: currentCompanyId.value, personId, email }),
      success: populate,
    })

  const init = () => {
    if (!list?.value?.length) get()
  }

  const clear = () => {
    list.value.forEach((item) => {
      delete items.value[item]
    })
    list.value.splice(0, list.value.length)
  }

  return {
    items,
    list,
    loading,
    get,
    update,
    init,
    add,
    sendVerification,
    clear,
    loaded,
    missingInformation,
    scoring,
  }
})
