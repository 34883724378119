<script lang="ts" setup>
import type { Icons } from '../utils/icons'

const props = withDefaults(
  defineProps<{
    tag?: string
    color?: 'primary' | 'white' | 'dark'
    variant?: 'border' | 'text' | 'base'
    shape?: 'pill' | 'base'
    loading?: boolean
    text?: string
    icon?: Icons
    iconPosition?: 'end' | 'start'
    size?: 'sm' | 'base' | 'xs'
    counter?: number | boolean
    textAlign?: 'left' | 'right' | 'center'
  }>(),
  {
    tag: 'button',
    size: 'base',
    iconPosition: 'end',
    variant: 'base',
    color: 'primary',
    shape: 'base',
    textAlign: 'center',
  },
)

const iconSize = computed(() => {
  switch (props.size) {
    case 'base':
    case 'sm':
      return 'md'
    case 'xs':
      return 'base'
  }
})
const fontSize = computed(() => {
  switch (props.size) {
    case 'base':
      return null
    case 'sm':
      return 'text-sm'
    case 'xs':
      return 'text-xs'
  }
})
</script>

<template>
  <component
    :is="tag"
    class="font-semibold inline-flex items-center gap-2 cursor-pointer outline-none transition disabled:pointer-events-none disabled:opacity-50 relative"
    :class="[
      {
        'pointer-events-none': loading,
        'h-14': size === 'base',
        'h-10': size === 'sm',
        'h-6': size === 'xs',
        'rounded-xl': size === 'base' && shape === 'base',
        'rounded-lg': size === 'sm' && shape === 'base',
        'rounded-md': size === 'xs' && shape === 'base',
        'rounded-full': shape === 'pill',
        'min-w-14 px-4': size === 'base' && !text,
        'min-w-10 px-2': size === 'sm' && !text,
        'min-w-6': size === 'xs' && !text,
        'px-[1.125rem]': text && ['base', 'sm'].includes(size),
        'px-1': text && size === 'xs',
        'focus:bg-focus': variant === 'base',
        'hover:bg-primary': variant === 'base' && ['dark', 'primary'].includes(color),
        'bg-grey-50 bg-opacity-20 hover:bg-focus hover:bg-opacity-100 focus:bg-opacity-100':
          variant === 'base' && color === 'white',
        'bg-transparent': variant === 'text',
        'border focus:border-focus': variant === 'border',
        'hover:border-primary': variant === 'border' && ['dark', 'primary'].includes(color),
        'hover:border-focus': variant === 'border' && color === 'white',
        'hover:text-primary focus:text-focus':
          ['border', 'text'].includes(variant) && ['dark', 'primary'].includes(color),
        'text-white bg-expose hover:bg-primary focus:bg-focus': color === 'primary' && variant === 'base',
        'text-expose': color === 'primary' && ['border', 'text'].includes(variant),
        'border-expose': color === 'primary' && variant === 'border',
        'text-dark': color === 'dark' && ['border', 'text'].includes(variant),
        'bg-lightgray text-darkgray hover:text-white focus:text-white': variant === 'base' && color === 'dark',
        'border-border': variant === 'border' && color === 'dark',
        'text-white': color === 'white',
        'hover:text-focus focus:text-focus': color === 'white' && ['border', 'text'].includes(variant),
        'shadow-lg disabled:shadow-none': color === 'primary' && size === 'base' && variant === 'base',
      },
      fontSize,
    ]"
  >
    <span
      v-if="text"
      class="leading-tight flex-grow order-2 whitespace-nowrap overflow-hidden overflow-ellipsis"
      :class="[{ 'opacity-0': loading }, `text-${textAlign}`]"
      v-html="text"
    />
    <span
      v-if="icon"
      class="flex justify-center items-center"
      :class="{
        'flex-grow': !text,
        'order-1': iconPosition === 'start',
        'order-3': iconPosition === 'end',
        'opacity-0': loading,
      }"
    >
      <d-icon :icon="icon" :size="iconSize" />
    </span>
    <span v-if="loading || counter" class="absolute inset-0 flex justify-center items-center">
      <loading-icon v-if="loading" class="h-5 w-5" />
      <d-message-counter v-if="counter" class="-top-2 -right-2 absolute" :count="counter" />
    </span>
  </component>
</template>
