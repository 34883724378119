import { useQueryClient } from '@tanstack/vue-query'
import { useUserStore } from '@/merchant/stores/user'
import { captureException } from '@sentry/vue'

// @TODO: move this to core/helpers cause IT IS NOT A HOOK or NEITHER A COMPOSABLE...
import { showToast } from '@/core/hooks'
import { useI18n } from 'vue-i18n'

import {
  createTransaction as createTransactionFn,
  getTransaction as getTransactionFn,
  createAssociation as createAssociationFn,
  cancelTransaction as cancelTransactionFn,
  type Transaction,
} from '@/merchant/api/transaction'
import sleep from '@/core/utils/sleep'

const POLLING_INTERVAL = 1000

const transaction = ref<Transaction | null>(null)

const useTransaction = () => {
  const queryClient = useQueryClient()
  const user = useUserStore()
  const { t } = useI18n()

  const getTransactionLoading = ref(false)

  /**
   * Prefetch a transaction
   *
   * @param reference - The reference of the transaction to prefetch
   */
  const prefetchTransaction = async (reference: string) => {
    await queryClient.prefetchQuery({
      queryKey: [
        'transaction',
        { reference: reference, companyId: user.currentCompanyId, pointOfSaleId: user.currentPointOfSaleId },
      ],
      queryFn: () =>
        getTransactionFn({ companyId: user.currentCompanyId, pointOfSaleId: user.currentPointOfSaleId, reference }),
      ...{
        staleTime: 1000 * 10, // 10 seconds
      },
    })
  }

  /**
   * Get a transaction
   *
   * @param options
   * @param options.reference - The reference of the transaction to get
   */
  const getTransaction = async (options: { reference: string }) => {
    getTransactionLoading.value = true
    try {
      const data = await getTransactionFn({
        companyId: user.currentCompanyId,
        pointOfSaleId: user.currentPointOfSaleId,
        reference: options.reference,
      })
      transaction.value = data
    } catch (err) {
      captureException(new Error('[useTransaction] Error while getting a transaction'))
      showToast({ type: 'error', message: t('errors.fatal') })
      console.error(err)
    } finally {
      getTransactionLoading.value = false
    }
  }

  /**
   * Set the transaction
   *
   * @param _transaction The transaction to set
   */
  const setTransaction = (_transaction: Transaction) => (transaction.value = _transaction)

  const createTransactionLoading = ref(false)

  /**
   * Create a transaction
   */
  const createTransaction = async (options: { amount: number; polling?: boolean }) => {
    createTransactionLoading.value = true

    try {
      const data = await createTransactionFn({
        companyId: user.currentCompanyId,
        pointOfSaleId: user.currentPointOfSaleId,
        checkoutNumber: user.currentCheckoutNumber,
        amount: options.amount,
      })

      transaction.value = data
    } catch (err) {
      captureException(new Error('[useTransaction] Error while creating a transaction'))
      showToast({ type: 'error', message: t('errors.fatal') })
      console.error(err)
    } finally {
      createTransactionLoading.value = false
    }
  }

  const refreshTransactionLoading = ref(false)

  /**
   * Refresh a transaction
   */
  const refreshTransaction = async (options: { polling?: boolean }) => {
    refreshTransactionLoading.value = true

    if (!transaction.value) {
      console.error('no transaction to refresh')
      return
    }

    try {
      const data = await getTransactionFn({
        companyId: user.currentCompanyId,
        pointOfSaleId: user.currentPointOfSaleId,
        reference: transaction.value.reference,
      })

      if (!transaction.value) return
      transaction.value = data

      if (options.polling && ['pending', 'associated'].includes(data.state.key)) {
        await sleep(POLLING_INTERVAL)
        if (transaction.value) await refreshTransaction({ polling: true })
      }
      return Promise.resolve()
    } catch (err) {
      captureException(new Error('[useTransaction] Error while refreshing a transaction'))
      console.error(err)
      showToast({ type: 'error', message: t('errors.fatal') })
    } finally {
      refreshTransactionLoading.value = false
    }
  }

  const associateTransactionLoading = ref(false)

  /**
   * Associate a transaction to a customer
   *
   * This function is used to associate a transaction to a customer.
   * The options should contain either:
   * - a phoneNumber and a countryCode
   * - a uuid
   *
   *  @param options
   *  @param options.phoneNumber - The phone number of the customer
   *  @param options.countryCode - The country code of the customer
   *  @param options.uuid - The uuid of the customer
   *
   */
  const associateTransaction = async (options: { countryCode?: string; phoneNumber?: string; uuid?: string }) => {
    if (!transaction.value) {
      captureException(new Error('[useTransaction] No transaction to associate with...'))
      showToast({ type: 'error', message: t('errors.fatal') })
      return
    }

    if (!options.countryCode && !options.phoneNumber && !options.uuid) {
      captureException(new Error('[useTransaction] Missing options to associate a transaction'))
      showToast({ type: 'error', message: t('errors.fatal') })
      return
    }

    associateTransactionLoading.value = true
    try {
      await createAssociationFn({
        companyId: user.currentCompanyId,
        reference: transaction.value.reference,
        pointOfSaleId: user.currentPointOfSaleId,

        ...(options.countryCode && { customerPhoneNumberCountryCode: options.countryCode }),
        ...(options.phoneNumber && { customerPhoneNumber: options.phoneNumber }),
        ...(options.uuid && { customerAccountUuid: options.uuid }),
      })

      await refreshTransaction({})
    } catch (err) {
      captureException(new Error('[useTransaction] Error while associating a transaction'))
      showToast({ type: 'error', message: t('errors.fatal') })
      console.error(err)
    } finally {
      associateTransactionLoading.value = false
    }
  }

  const cancelTransactionLoading = ref(false)

  /**
   * Cancel a transaction
   *
   * @param options
   * @param options.reference - The reference of the transaction to cancel
   */
  const cancelTransaction = async (options: { reference: string }) => {
    cancelTransactionLoading.value = true
    try {
      await cancelTransactionFn({
        companyId: user.currentCompanyId,
        pointOfSaleId: user.currentPointOfSaleId,
        reference: options.reference,
      })
    } catch (err) {
      captureException(new Error('[useTransaction] Error while canceling a transaction'))
      showToast({ type: 'error', message: t('errors.fatal') })
      console.error(err)
    } finally {
      cancelTransactionLoading.value = false
    }
  }

  /**
   * Renew a transaction
   *
   * Generally used when a transaction is expired, candeled or for a split bill
   *
   * @param options
   * @param options.transaction - The transaction to renew
   */
  const renewTransaction = async (options: { transaction: Transaction }) => {
    // Here i use the "+" to cast the string to a number event if it shoud be a number
    // because it's definitly not a number
    await createTransaction({ amount: +options.transaction.amount.raw })
  }

  const reset = () => (transaction.value = null)

  return {
    transaction: computed(() => transaction.value),

    prefetchTransaction,

    getTransaction,
    getTransactionLoading: computed(() => getTransactionLoading.value),

    setTransaction,

    createTransaction,
    createTransactionLoading: computed(() => createTransactionLoading.value),

    associateTransaction,
    associateTransactionLoading: computed(() => associateTransactionLoading.value),

    refreshTransaction,
    refreshTransactionLoading: computed(() => refreshTransactionLoading.value),

    cancelTransaction,
    cancelTransactionLoading: computed(() => cancelTransactionLoading.value),

    renewTransaction,
    renewTransactionLoading: computed(() => createTransactionLoading.value),

    reset,
  }
}

export default useTransaction
