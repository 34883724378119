import { useMutation } from '@tanstack/vue-query'

import instance, { getPagination, mapData } from '@/core/api/client'
import type { PaginationRequestParams } from '@/core/api/client'

import type { MessageAttachment } from '@/merchant/api/chat'

import type { CountryCode } from '@/core/utils/countries'
import { z } from 'zod'

export interface Currency {
  code: string
  symbol: string
  name: string
}

export interface Amount {
  localized: string
  raw: number
}

export interface AbsoluteAmount extends Amount {
  positive?: boolean
}

export interface TransactionCustomer {
  uuid: string
  name: string
  avatarUrl: string
  device: string
}

export type TransactionStateKey =
  | 'pending'
  | 'associated'
  | 'validated'
  | 'expired'
  | 'refused'
  | 'refunded'
  | 'canceled'

export type DisputeStateKey =
  | 'waiting_for_merchant'
  | 'accepted_by_merchant'
  | 'refused_by_merchant'
  | 'merchant_expired'
  | 'customer_expired'
  | 'confirmed_by_customer'
  | 'closed'

export const transactionStatesList: TransactionStateKey[] = [
  'pending',
  'associated',
  'validated',
  'expired',
  'refused',
  'refunded',
  'canceled',
]

export interface TransactionState {
  key: TransactionStateKey
  label: string
}

export interface DisputeState {
  key: DisputeStateKey
  label: string
}

export interface TransactionActivity {
  createdAt: string
  state: TransactionState
}

export interface DisputeActivity {
  createdAt: string
  state: DisputeState
}

export interface Dispute {
  reference: string
  reason: string
  merchantRefusalReason: string
  confirmationReason: string
  secondsBeforeMerchantExpiration: number
  secondsBeforeCustomerExpiration: number
  customerExpiredAt: Date
  merchantExpiredAt: Date
  createdAt: string
  state: DisputeState
  amount: Amount
  attachments: MessageAttachment[]
  permissions: {
    canAccept: boolean
    canRefuse: boolean
  }
  motivation: {
    key: string
    label: string
  }
  activities: DisputeActivity[]
}

export interface Transaction {
  reference: string
  amount: Amount
  amountWithoutCommission: Amount
  commission: Amount
  commissionRate: string
  createdAt: string
  qrCodeCode: string
  qrCodeUrl: string
  source: {
    title: string
    me: boolean
  }
  messagesLockedAt: string | null
  messagesToReadCount: number
  messagesCount: number
  target: {
    title: string
    me: boolean
  }
  state: TransactionState
  customerViewedAt: string | null
  refund: {
    amount: Amount
    amountWithoutCommission: Amount
    commission: Amount
    comment: string | null
    count: number
    createdAt: string | null
    partial: boolean
    refundableAmount: Amount
    refundableAmountWithoutCommission: Amount
    refundableCommission: Amount
    transactions: Transaction[]
  }
  checkout: {
    id: number
    title: string
  }
  permissions: {
    canSendMessage: boolean
    canSeeMessages: boolean
  }
  customer: TransactionCustomer | null
  employee: {
    name: string
  }
  merchant: {
    uuid: string
    name: string
    avatarUrl: string
    device: string
  }
  activities?: TransactionActivity[]
  dispute?: Dispute
}

export interface TransactionFilters {
  state: TransactionStateKey[]
  minAmount?: string
  maxAmount?: string
  ongoingChat?: boolean
  checkoutId?: string
}

export interface GetTransactionsResponseData {
  transactions: Transaction[]
}

export interface GetTransactionResponseData {
  transaction: Transaction
}

export interface CreateTransactionResponseData {
  transaction: Transaction
}

export interface GetTransactionRequestParams extends Partial<PaginationRequestParams> {
  state?: TransactionStateKey[]
  from?: Date | null
  to?: Date | null
  search?: string | null
  minAmount?: string | null
  maxAmount?: string | null
  ongoingChat?: boolean | null
  checkoutId?: string | null
  disputeState?: string | null
}

/**
 * New api routes
 */

const getTransactionsOptionsQuery = z.object({
  companyId: z.number().positive(),
  pointOfSaleId: z.number().positive(),
  page: z.number().optional(),
  limit: z.number().optional(),
  state: z.string().optional(),
  ongoingChat: z.boolean().optional(),
  from: z.date().optional(),
  to: z.date().optional(),
  minAmount: z.string().optional(),
  maxAmount: z.string().optional(),
  checkoutId: z.string().optional(),
  disputeState: z.string().optional(),
  search: z.string().optional(),
})

type GetTransactionsOptions = z.infer<typeof getTransactionsOptionsQuery>

export const getTransactions = async (params: Partial<GetTransactionsOptions>) => {
  const page = params.page ?? 1
  const limit = params.limit ?? 10

  const options = getTransactionsOptionsQuery.parse(params)

  const res = await instance.get<{ transactions: Transaction[] }>(
    `/companies/${options.companyId}/point_of_sales/${options.pointOfSaleId}/transactions/`,
    {
      params: {
        per_page: limit,
        page: page,
        ...(options.state && { state: options.state }),
        ...(options.ongoingChat && { ongoing_chat: options.ongoingChat }),
        ...(options.from && { from: options.from.toISOString() }),
        ...(options.to && { to: options.to.toISOString() }),
        ...(options.minAmount && { min_amount: options.minAmount }),
        ...(options.maxAmount && { max_amount: options.maxAmount }),
        ...(options.checkoutId && { checkout_id: options.checkoutId }),
        ...(options.disputeState && { dispute_state: options.disputeState }),
        ...(options.search && { search: options.search }),
      },
    },
  )

  const pagination = getPagination(res)

  return {
    transactions: mapData(res.data.transactions, true),
    total: pagination.total,
    nextCursor: pagination.nextPage,
    previousCursor: pagination.prevPage,
  }
}

export interface GetTransactionOptions {
  companyId: number
  pointOfSaleId: number
  reference: string
}

export const getTransaction = async (options: GetTransactionOptions) => {
  const res = await instance.get<GetTransactionResponseData>(
    `/companies/${options.companyId}/point_of_sales/${options.pointOfSaleId}/transactions/${options.reference}`,
  )

  // @TODO Remove me
  return mapData(res.data.transaction, true)
}

interface CancelTransactionOptions {
  companyId: number
  pointOfSaleId: number
  reference: string
  targetAccount?: string
}
export const cancelTransaction = async (options: CancelTransactionOptions) => {
  return await instance.post(
    `/companies/${options.companyId}/point_of_sales/${options.pointOfSaleId}/transactions/${options.reference}/cancel`,
    {
      ...(options.targetAccount && { target_account: options.targetAccount }),
    },
  )
}

export interface CreateAssociationPayload {
  association: {
    customerMobilePhoneNumber?: string
    customerMobilePhoneNumberCountryCode?: CountryCode
    customerAccountUuid?: string
  }
}

export interface CreateAssociationResponseData {
  message: string
}

const createAssociationOptionsQuery = z
  .object({
    companyId: z.number(),
    pointOfSaleId: z.number(),
    reference: z.string(),
    customerPhoneNumber: z.string().optional(),
    customerPhoneNumberCountryCode: z.string().optional(),
    customerAccountUuid: z.string().optional(),
  })
  .refine((data) => (data.customerPhoneNumber && data.customerPhoneNumberCountryCode) || data.customerAccountUuid, {
    message: 'You must provide either a customer phone number and a country code or a customer account uuid',
    path: ['customerPhoneNumber', 'customerAccountUuid'],
  })

export type CreateAssociationOptions = z.infer<typeof createAssociationOptionsQuery>

export const createAssociation = async (params: Partial<CreateAssociationOptions>) => {
  const options = createAssociationOptionsQuery.parse(params)
  const url = `/companies/${options.companyId}/point_of_sales/${options.pointOfSaleId}/transactions/${options.reference}/association`

  const res = await instance.post<CreateAssociationResponseData>(url, {
    association: {
      ...(options.customerPhoneNumber && { customer_mobile_phone_number: options.customerPhoneNumber }),
      ...(options.customerPhoneNumberCountryCode && {
        customer_mobile_phone_number_country_code: options.customerPhoneNumberCountryCode,
      }),
      ...(options.customerAccountUuid && { customer_account_uuid: options.customerAccountUuid }),
    },
  })

  return mapData(res.data, true)
}

const createTransactionOptionsQuery = z.object({
  companyId: z.number(),
  pointOfSaleId: z.number(),
  checkoutNumber: z.string().optional(),
  amount: z.number(),
})

type CreateTransactionOptions = z.infer<typeof createTransactionOptionsQuery>

export const createTransaction = async (params: CreateTransactionOptions) => {
  const options = createTransactionOptionsQuery.parse(params)
  const url = `/companies/${options.companyId}/point_of_sales/${options.pointOfSaleId}/checkouts/${options.checkoutNumber}/transactions`

  const res = await instance.post<CreateTransactionResponseData>(url, {
    amount: options.amount,
  })

  return mapData(res.data.transaction, true)
}

/**
 * Old api routes
 */

export type TransactionDocumentType = 'receipt' | 'commission_credit'

export interface GetTransactionDocumentOptions {
  companyId: number
  pointOfSaleId: number
  reference: string
  type?: TransactionDocumentType
}

export const getTransactionReceipt = async ({ companyId, pointOfSaleId, reference }: GetTransactionDocumentOptions) => {
  const url = `/companies/${companyId}/point_of_sales/${pointOfSaleId}/transactions/${reference}/receipt`
  // @NOTE i've set the data type to unknown because i prefer this as infer something i don't know
  // p.s i've done this cause we return mapData(res.data, true) and i don't know what is the type of res.data
  // @NOTE 2 i've set the responseType to BlobPart because the view breaks if i set it to undefined
  const res = await instance.get<BlobPart>(url, {
    responseType: 'blob',
  })

  // @TODO Refactor me (the view breaks)
  // if (!res?.data) return

  // @NOTE mapData on a BlobPart ?
  return mapData(res.data, true)
}

export const getTransactionCommissionInvoice = async ({
  companyId,
  pointOfSaleId,
  reference,
}: GetTransactionDocumentOptions) => {
  const url = `/companies/${companyId}/point_of_sales/${pointOfSaleId}/transactions/${reference}/commission_invoice`

  const res = await instance.get<BlobPart>(url, {
    responseType: 'blob',
  })

  // @TODO Refactor me (the view breaks)
  // if (!res?.data) return

  return mapData(res.data, true)
}

export const getTransactionDocument = async ({
  companyId,
  pointOfSaleId,
  reference,
  type,
}: GetTransactionDocumentOptions) => {
  const url = `/companies/${companyId}/point_of_sales/${pointOfSaleId}/transactions/${reference}/${type}`

  const res = await instance.get<BlobPart>(url, {
    responseType: 'blob',
  })

  // @TODO Refactor me (the view breaks)
  // if (!res?.data) return

  return mapData(res.data, true)
}

export interface CreateTransactionPayload {
  amount: string
}

export interface CreateRefundResponseData {
  transaction: Transaction
}

export interface CreateRefundPayload {
  refund: {
    amount: number
  }
}

const createRefundOptionsQuery = z.object({
  companyId: z.number(),
  pointOfSaleId: z.number(),
  transactionReference: z.string(),
  refundAmount: z.number(),
})

type CreateRefundOptions = z.infer<typeof createRefundOptionsQuery>

export const refundTransaction = async (_options: CreateRefundOptions) => {
  const options = createRefundOptionsQuery.parse(_options)

  const url = `/companies/${options.companyId}/point_of_sales/${options.pointOfSaleId}/transactions/${options.transactionReference}/refund`

  const res = await instance.post<CreateRefundResponseData>(url, {
    refund: {
      amount: options.refundAmount,
    },
  })

  return mapData(res.data, true)
}

interface LockTransactionMessagesPayload {
  companyId: number
  pointOfSaleId: number
  transactionReference: string
}

interface LockTransactionResponseData {}

export const lockTransactionMessages = () => {
  const mutationFn = async (payload: LockTransactionMessagesPayload) => {
    const url = `/companies/${payload.companyId}/point_of_sales/${payload.pointOfSaleId}/transactions/${payload.transactionReference}/messages_lock`

    const res = await instance.post<LockTransactionResponseData>(url)

    return mapData(res.data, true)
  }

  return {
    ...useMutation({ mutationFn }),
  }
}

export interface ManageDisputeReason {
  acceptation?: {
    reason: string
  }
  refusal?: {
    reason: string
  }
}

export interface ManageDisputeOptions {
  companyId: number
  pointOfSaleId: number
  transactionReference: string
  accept: boolean
  payload: ManageDisputeReason
}

export const manageDispute = async ({
  companyId,
  pointOfSaleId,
  transactionReference,
  accept,
  payload,
}: ManageDisputeOptions) => {
  const url = `/companies/${companyId}/point_of_sales/${pointOfSaleId}/transactions/${transactionReference}/dispute/${
    accept ? 'acceptation' : 'refusal'
  }`

  const res = await instance.post(url, mapData(payload, false))

  return !!res?.data
}
