import { defineStore } from 'pinia'

import { manageStore } from '@/merchant/utils/storeListManager'
import { getSystemNotifications, isImportantNotificationKey } from '@/merchant/api/systemNotifications'

import type { SystemNotification } from '@/merchant/api/systemNotifications'

export const useSystemNotificationStore = defineStore('systemNotification', () => {
  const items = ref<{ [id: string]: SystemNotification }>({})
  const list = ref<string[]>([])
  const loading = ref<boolean>(false)

  const importantNotifications = computed(() =>
    list.value.filter((uuid) => isImportantNotificationKey(items.value[uuid].key))
  )

  const recoveryIban = computed<string | undefined>(() => {
    const i = list.value.find((id) => !!items.value[id].data?.recoveryIban)
    if (!i) return
    return items.value[i].data?.recoveryIban
  })

  const get = () =>
    manageStore({
      loading,
      request: () => getSystemNotifications(),
      success: (result: SystemNotification[]) => {
        list.value.splice(0, list.value.length)
        result.forEach((n) => {
          items.value[n.uuid] = n
          list.value.push(n.uuid)
        })
      }
    })

  const init = () => {
    if (list.value.length) return
    get()
  }

  const clear = () => {
    Object.keys(items.value).forEach((item) => {
      delete items.value[item]
    })
    list.value.splice(0, list.value.length)
  }

  return { items, list, loading, get, init, clear, importantNotifications, recoveryIban }
})
