<script setup lang="ts">
import { useRoute } from 'vue-router'

import { useUserStore } from '@/merchant/stores/user'

const { t } = useI18n()
const user = useUserStore()
const showCreateTransaction = ref(false)
const route = useRoute()

import { IconLayout, IconCirclePlus, IconExchanges, IconWarning, IconCog } from '@delupay/web-core/icons'
</script>

<template>
  <div class="flex items-center gap-4 sm:gap-12 md:gap-24 border-t z-2 text-center">
    <router-link :to="{ name: 'merchant.dashboard' }" class="nav-link">
      <IconLayout class="h-5 w-5" />
      <span class="text-xxxs">{{ t('navigation.main.dashboard') }}</span>
    </router-link>

    <a v-if="!user.closureConfirmedAt" href="#" class="nav-link" @click.prevent="showCreateTransaction = true">
      <IconCirclePlus class="h-5 w-5" />
      <span class="text-xxxs">{{ t('navigation.main.create_transaction') }}</span>
    </a>
    <Modal v-model="showCreateTransaction" no-padding>
      <CreateTransactionCard @close:modale="showCreateTransaction = false" />
    </Modal>

    <router-link
      :to="{
        name: 'transactions.index',
        query: { disputes: 'false' },
      }"
      class="nav-link"
      :class="{ 'router-link-active': route.query.disputes === 'false' }"
      active-class=""
    >
      <IconExchanges class="h-5 w-5" />
      <span class="text-xxxs">{{ t('navigation.main.transactions') }}</span>
    </router-link>
    <router-link
      :to="{
        name: 'transactions.index',
        query: { disputes: 'true' },
      }"
      class="nav-link"
      :class="{ 'router-link-active': route.query.disputes === 'true' }"
      active-class=""
    >
      <IconWarning class="h-5 w-5" />
      <span class="text-xxxs">{{ t('navigation.main.disputes') }}</span>
    </router-link>
    <router-link
      :to="{ name: 'settings.point_of_sale' }"
      class="nav-link"
      :class="{ 'router-link-active': route.name && route.name.toString().includes('settings.point_of_sale') }"
    >
      <IconCog class="h-5 w-5" />
      <span class="text-xxxs">{{ t('navigation.main.settings') }}</span>
    </router-link>
  </div>
</template>

<style scoped>
.backface-hidden {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
</style>
