import { ref, watch } from 'vue'

import instance, { mapData } from '@/core/api/client'

import { useMutation, useQuery } from '@tanstack/vue-query'

import { z } from 'zod'

import type { AuthToken } from '@/core/api/session'
import type { Country, CountryCode } from '@/core/utils/countries'
import type { CamelTrackingData } from '@/core/utils/trafic-origin'
import type { Company, PointOfSale } from './company'

export type RegistrationLocale = 'fr' | 'en'

export interface Registration {
  gender: 'male' | 'female' | null
  nationalityCountryCode: string | null
  companies: Company[]
  pointOfSales: PointOfSale[]
  confirmedAt: string | null
  email: string
  unconfirmedEmail: string | null
  firstName: string | null
  lastName: string | null
  locale: RegistrationLocale
  mobilePhoneNumber: string | null
  mobilePhoneNumberConfirmedAt: string | null
  mobilePhoneNumberCountry: Country | null
  pendingSponsorshipRequest: boolean
  notificationAuthenticationSuccess: boolean
  notificationTransactionValidated: boolean
  notificationTransactionValidatedMinimalAmount: number | null
  notificationMessageSent: boolean
  twoFactorEnabled: boolean
  avatarUrl: string | undefined
  uuid: string | null
  city: string | null
  cityOfBirthCode: string | null
  country: Country | null
  countryOfBirthCode?: CountryCode
  countryOfBirth: Country | null
  placeOfBirth: string | null
  street1: string | null
  street2: string | null
  zipCode: string | null
  sponsorshipToken?: string
  notAvailableCountry?: Country
  notAvailableCountryCode?: CountryCode
  notAvailableNotifySet?: boolean
  notAvailableNotifySetAt?: Date | null
  closureConfirmedAt?: Date | null
  closureNoticeUntil?: Date | null
  closureScheduledAt?: Date | null
  preferredPaymentMethods: Record<string, boolean>
}

export interface GetRegistrationResponseData {
  registration: Registration
}

export const getUserInfos = async (token?: string) => {
  const res = await instance.get<GetRegistrationResponseData>('/registration', {
    params: token ? { partner_token: token } : undefined,
  })

  if (!res.data) throw new Error('No registration data')

  return mapData(res.data, true)
}

export const getRegistration = async (token?: string) => {
  return await getUserInfos(token)
}

export interface UpdateRegistrationResponseData {
  registration: Registration
}

export interface UpdateRegistrationPayload {
  registration: Partial<Registration> & {
    preferredPaymentMethods?: string[]
  }
}

export const apiUpdateRegistration = async (payload: UpdateRegistrationPayload) => {
  const res = await instance.patch<UpdateRegistrationResponseData>('/registration', mapData(payload, false))

  return mapData(res.data, true)
}

export const updateRegistration = () => {
  return {
    ...useMutation({ mutationFn: apiUpdateRegistration }),
  }
}

export interface CreateRegistrationResponseData {
  registration: Registration
  metadata?: {
    token?: AuthToken
  }
}

export interface CreateRegistrationPayload {
  partnerToken?: string
  sponsorshipToken?: string
  registration: {
    email: string
    firstName?: string
    lastName?: string
    password?: string
    countryOfBirthCode?: string
    cityOfBirthCode?: string
    mobilePhoneNumber?: string
    mobilePhoneNumberCountryCode?: string
    placeOfBirth?: string
    analyticsTrackers?: { [key in CamelTrackingData]?: string }
  }
}

export const createRegistration = () => {
  const email = ref<string | undefined>(undefined)
  const firstName = ref<string | undefined>(undefined)
  const lastName = ref<string | undefined>(undefined)
  const password = ref<string | undefined>(undefined)
  const countryOfBirthCode = ref<string | undefined>(undefined)
  const sponsorshipToken = ref<string | undefined>(undefined)

  const mutationFn = async (payload: CreateRegistrationPayload) => {
    const res = await instance<CreateRegistrationResponseData>('/registration', {
      method: payload.partnerToken ? 'PATCH' : 'POST',
      data: mapData(payload, false),
      params: {
        email: payload.registration.email,
      },
    })

    return mapData(res.data, true)
  }

  return {
    email,
    firstName,
    lastName,
    password,
    countryOfBirthCode,
    sponsorshipToken,
    ...useMutation({ mutationFn }),
  }
}

const resendConfirmationEmailParser = z.object({
  registration: z.object({
    email: z.string().email(),
  }),
})

type ResendConfirmationEmailOptions = z.infer<typeof resendConfirmationEmailParser>

export const apiResendConfirmationEmail = async (_options: ResendConfirmationEmailOptions) => {
  const options = resendConfirmationEmailParser.parse(_options)

  const res = await instance.post<CreateRegistrationResponseData>('/registration', {
    ...options,
  })

  return mapData(res.data, true)
}

export interface CreateConfirmationPayload {
  confirmation: {
    email: string
    token: string
    reconfirmation?: boolean
  }
}

export interface CreateConfirmationResponseData {
  token: AuthToken
}

const validateEmailParser = z.object({
  email: z.string().email(),
  token: z.string().regex(/^\d{3}-\d{3}$/),
  reconfirmation: z.boolean(),
})

type ValidateEmailOptions = z.infer<typeof validateEmailParser>

export const validateEmail = async (_options: ValidateEmailOptions) => {
  const options = validateEmailParser.parse(_options)

  const res = await instance.post<CreateConfirmationResponseData>('/registration/confirmation', {
    confirmation: options,
  })

  return res.data
}

export const confirmRegistration = () => {
  const email = ref<string | null>(null)
  const token = ref<string | null>(null)
  const mutationFn = async (payload: CreateConfirmationPayload) => {
    const res = await instance.post<CreateConfirmationResponseData>('/registration/confirmation', {
      ...mapData(payload, false),
    })

    return mapData(res.data, true)
  }

  return {
    email,
    token,
    ...useMutation({ mutationFn }),
  }
}

export interface UpdateEmailRegistrationPayload {
  email?: string
  password: string
}

export interface UpdateEmailOptions {
  onError: () => void
  onSuccess: () => void
}

export const updateEmailRegistration = (opt: UpdateEmailOptions) => {
  const mutationFn = async (payload: UpdateEmailRegistrationPayload): Promise<Registration> => {
    const res = await instance.patch<Registration>('/registration/email', {
      ...mapData(payload, false),
    })

    return mapData(res.data, true)
  }

  return useMutation({ mutationFn, ...opt })
}

export const createRegistrationAvatar = () => {
  const formData = ref(new FormData())
  const file = ref<File | null>(null)
  const image = ref(new Image())

  watch(file, (value) => {
    if (value && typeof value === 'object') {
      formData.value.append('registration[avatar]', value)
    }
  })

  const mutationFn = async () => {
    const res = await instance.post<GetRegistrationResponseData>('registration/avatar', formData.value, {
      headers: { 'content-type': 'multipart/form-data' },
    })

    return mapData(res.data, true)
  }

  return {
    file,
    image,
    ...useMutation({ mutationFn }),
  }
}

export const deleteRegistrationAvatar = () => {
  const mutationFn = async () => {
    const res = await instance.delete<GetRegistrationResponseData>(`registration/avatar`)

    return mapData(res.data, true)
  }

  return useMutation({ mutationFn })
}

export interface Address {
  label: string
  street1: string
  zipCode: string
  city: string
}

export interface City {
  cityCode: string
  zipCode?: string
  city: string
}

export interface GetPlacesResponseData {
  cities?: City[]
  addresses?: Address[]
}

export interface GetPlacesOptions {
  searchCities: boolean
}

const apiGetPlacesParser = z.object({
  search: z.string(),
  searchCities: z.boolean(),
})

export const apiGetPlaces = async (options: z.infer<typeof apiGetPlacesParser>) => {
  const { searchCities } = options
  const path = searchCities ? '/cities/' : '/addresses/'
  const res = await instance.get<GetPlacesResponseData>(path, {
    params: {
      search: options.search,
    },
  })

  return mapData(res.data, true)
}

export const getPlaces = (opt: GetPlacesOptions) => {
  const search = ref<string | null>(null)
  const trigger = ref(false)
  const searchDebounced = debouncedRef(search, 500)

  const options = reactive({
    enabled: computed(() => !!searchDebounced.value && searchDebounced.value.length > 2 && trigger.value),
    refetchOnWindowFocus: false,
  })

  return {
    search,
    trigger,
    ...useQuery({
      queryKey: reactive(['search', { searchDebounced, ...opt }]),
      queryFn: async () =>
        await apiGetPlaces({
          ...opt,
          search: searchDebounced.value ?? '',
        }),
      ...options,
    }),
  }
}

export const placeIsCity = (place: City | Address): place is City => {
  return (place as City).cityCode !== undefined
}
