import type { TransactionStateKey } from '@/merchant/api/transaction'
import type { Icons } from '@/core/utils/icons'
import type { AppColor } from '@/core/utils/colors'

export const transactionStateIcon: { [key in TransactionStateKey]: Icons } = {
  validated: 'circle-check',
  refused: 'circle-close',
  expired: 'circle-warning',
  associated: 'circle-person',
  pending: 'circle-loading',
  refunded: 'circle-refund',
  canceled: 'circle-close',
}

export const transactionStateColor: { [key in TransactionStateKey]: AppColor } = {
  validated: 'green-600',
  refused: 'danger',
  expired: 'danger',
  associated: 'warning',
  pending: 'warning',
  refunded: 'info',
  canceled: 'danger',
}
