<script setup lang="ts">
import { useUserStore } from '@/merchant/stores/user'
import { storeToRefs } from 'pinia'

const user = useUserStore()

const { currentPointOfSale, pointOfSales, currentPointOfSaleId } = storeToRefs(user)

import type { PointOfSale } from '@/merchant/api/company'

const showCompaniesDropdown = ref(false)

const updateCurrentPointOfSale = (pointOfSale: PointOfSale, close: () => void) => {
  user.setActiveCompanyId(pointOfSale.companyId)
  user.setActivePointOfSaleId(pointOfSale.id)
  user.activeCheckoutNumber = null
  showCompaniesDropdown.value = false

  close()
}
</script>

<template>
  <Popover>
    <template #button>
      <div
        class="bg-grey-50 bg-opacity-25 h-[2.625rem] px-4 rounded-full flex items-center gap-1 cursor-pointer md:min-w-96"
      >
        <Icon class="h-4 w-4 text-black" icon="store" />
        <span
          v-if="currentPointOfSale"
          class="truncate font-semibold text-center text-grey-900 overflow-ellipsis max-w-sm"
        >
          {{ currentPointOfSale.title }}
        </span>
        <Icon class="ml-auto h-3 w-3 ui-open:rotate-180 ui-open:transform" icon="chevron-down" />
      </div>
    </template>
    <template v-slot:panel="{ close }">
      <div
        v-for="pointOfSale in pointOfSales"
        :key="pointOfSale.id"
        class="cursor-pointer flex items-center justify-between gap-2 hover:bg-grey-50 duration-200 min-w-full p-2 rounded-xl"
        @click.prevent="updateCurrentPointOfSale(pointOfSale, close)"
      >
        <Icon class="h-4 w-4 text-black self-start" icon="store" />
        <div class="grid gap-1 text-black w-full">
          <div class="flex items-center gap-3">
            <div class="leading-none sm:whitespace-nowrap font-semibold">
              {{ pointOfSale.title }}
            </div>
            <span v-if="pointOfSale.id === currentPointOfSaleId" class="flex-none ml-auto">
              <Icon class="h-4 w-4 text-success" icon="circle-check" />
            </span>
          </div>
          <div>
            <div class="text-sm">SIRET : {{ pointOfSale.siret }}</div>
            <div class="text-xs text-gray-500">{{ pointOfSale.address }}</div>
          </div>
        </div>
      </div>
    </template>
  </Popover>
</template>
