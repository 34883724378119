<script lang="ts" setup>
import { showToast } from '@/core/hooks'

import type { Icons } from '@/core/utils/icons'

const { copy, copied } = useClipboard()

const props = withDefaults(
  defineProps<{
    value: string
    hideText?: boolean
    text?: string
    icon?: Icons
  }>(),
  {
    text: 'Copier le code',
    icon: 'copy',
  },
)

const emit = defineEmits(['copied'])

const onClick = () => {
  if (!props.value) return
  copy(props.value)
  emit('copied')
}

whenever(copied, () => {
  showToast({
    type: 'success',
    message: 'Copié',
    timeout: 2000,
  })
})
</script>

<template>
  <d-button v-bind="$attrs" :text="hideText ? undefined : text" :icon="icon" @click="onClick" />
</template>
