import { z } from 'zod'
import instance, { getTotalFromHeaders } from '@/core/api/client'

import type { Item } from './common'
import { captureException } from '@sentry/vue'
import type { _Checkout } from '@/types/TrueTypes'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 10

const baseCheckoutParamsParser = z.object({
  company_id: z.number(),
  point_of_sale_id: z.number(),
})

export const getCheckoutsParser = baseCheckoutParamsParser.extend({
  page: z.number().optional(),
  per_page: z.number().optional(),
})

/**
 * Fetch all the checkouts
 *
 * @param {Object} _options
 * @param {number} _options.company_id - The company ID
 * @param {number} _options.point_of_sale_id - The point of sale ID
 * @param {number} [_options.page=1] - The page number
 * @param {number} [_options.per_page=10] - The number of items per page
 */
export const apiGetCheckouts = async (_options: z.infer<typeof getCheckoutsParser>) => {
  const options = getCheckoutsParser.parse(_options)

  const url = `/companies/${options.company_id}/point_of_sales/${options.point_of_sale_id}/checkouts`

  try {
    const res = await instance.get<{
      checkouts: _Checkout[]
      total: number
    }>(url, {
      params: {
        page: options.page ?? DEFAULT_PAGE,
        per_page: options.per_page ?? DEFAULT_PER_PAGE,
      },
    })

    return {
      checkouts: res.data.checkouts,
      total: getTotalFromHeaders(res),
    }
  } catch (error) {
    if (import.meta.env.DEV) console.error(error)
    captureException(error)
  }
}

export const getCheckoutParser = baseCheckoutParamsParser.extend({
  checkout_number: z.string(),
})

/**
 * Fetch a single checkout
 *
 * @param {Object} _options
 * @param {number} _options.company_id - The company ID
 * @param {number} _options.point_of_sale_id - The point of sale ID
 * @param {string} _options.checkout_number - The checkout number
 */
export const apiGetCheckout = async (_options: z.infer<typeof getCheckoutParser>) => {
  const options = getCheckoutParser.parse(_options)

  const url = `/companies/${options.company_id}/point_of_sales/${options.point_of_sale_id}/checkouts/${options.checkout_number}`

  try {
    const { data } = await instance.get<{ checkout: _Checkout }>(url)

    return data.checkout
  } catch (error) {
    if (import.meta.env.DEV) console.error(error)
    captureException(error)
  }
}

export const storeCheckoutParser = baseCheckoutParamsParser.extend({
  checkout: z.object({
    online: z.boolean(),
    offline_type_id: z.number().optional(),
    online_type_id: z.number().optional(),
    checkout_only: z.boolean().optional(),
    title: z.string(),
  }),
})

/**
 * Create a checkout
 *
 * @param {Object} _options
 * @param {number} _options.company_id - The company ID
 * @param {number} _options.point_of_sale_id - The point of sale ID
 * @param {Object} _options.checkout - The checkout object
 * @param {string} _options.checkout.title - The checkout title
 * @param {boolean} _options.checkout.online - If this is an "online" (CMS) checkout, put this to "true"
 * @param {number} [_options.checkout.offline_type_id] - The checkout offline type ID
 * @param {number} [_options.checkout.online_type_id] - The checkout online type ID
 * @param {boolean} [_options.checkout.checkout_only] - If this is a "checkout_only" checkout, put this to "true"
 */
export const apiStoreCheckout = async (_options: z.infer<typeof storeCheckoutParser>) => {
  const options = storeCheckoutParser.parse(_options)

  const url = `/companies/${options.company_id}/point_of_sales/${options.point_of_sale_id}/checkouts`

  try {
    const { data } = await instance.post<{ checkout: _Checkout }>(url, { checkout: options.checkout })

    return data.checkout
  } catch (error) {
    if (import.meta.env.DEV) console.error(error)
    captureException(error)
  }
}

export const updateCheckoutParser = baseCheckoutParamsParser.extend({
  checkout: z.object({
    title: z.string(),
    checkout_only: z.boolean(),
  }),
  checkout_number: z.string(),
})

/**
 * Update a checkout
 *
 * @param {Object} _options
 * @param {number} _options.company_id - The company ID
 * @param {number} _options.point_of_sale_id - The point of sale ID
 * @param {Object} _options.checkout - The checkout object
 * @param {string} _options.checkout.title - The checkout title
 * @param {string} _options.checkout_number - The checkout number
 */
export const apiUpdateCheckout = async (_options: z.infer<typeof updateCheckoutParser>) => {
  const options = updateCheckoutParser.parse(_options)

  const url = `/companies/${options.company_id}/point_of_sales/${options.point_of_sale_id}/checkouts/${options.checkout_number}`

  try {
    const { data } = await instance.patch<{ checkout: _Checkout }>(url, { checkout: options.checkout })

    return data.checkout
  } catch (error) {
    if (import.meta.env.DEV) console.error(error)
    captureException(error)
  }
}

export const deleteCheckoutParser = baseCheckoutParamsParser.extend({
  checkout_number: z.string(),
})

/**
 * Delete a checkout
 *
 * @param {Object} _options
 * @param {number} _options.company_id - The company ID
 * @param {number} _options.point_of_sale_id - The point of sale ID
 * @param {string} _options.checkout_number - The checkout number
 */
export const apiDeleteCheckout = async (_options: z.infer<typeof deleteCheckoutParser>) => {
  const options = deleteCheckoutParser.parse(_options)

  const url = `/companies/${options.company_id}/point_of_sales/${options.point_of_sale_id}/checkouts/${options.checkout_number}`

  try {
    const { data } = await instance.delete<boolean>(url)
    return data
  } catch (error) {
    if (import.meta.env.DEV) console.error(error)
    captureException(error)
  }
}

/**
 * Fetch all the checkout online types (Wordpress, Magento...)
 */
export const apiGetCheckoutOnlineTypes = async (): Promise<Item[] | undefined> => {
  try {
    const res = await instance.get<{ list_items: Item[] }>(`/checkout_online_types`)

    return res.data.list_items
  } catch (error) {
    if (import.meta.env.DEV) console.error(error)
    captureException(error)
  }
}
