import instance, { mapData } from '@/core/api/client'

import type { CamelCase, Camelize } from '@/core/utils/ts-typing'
import type { CountryCode } from '@/core/utils/countries'
import type { IdentityVerification } from '@/merchant/api/company'
import { z } from 'zod'

export type Gender = 'male' | 'female'

export interface ServerPersonForm {
  first_name: string
  last_name: string
  gender: Gender
  date_of_birth: string
  country_code: CountryCode
  country_of_birth_code: CountryCode
  nationality_country_code: CountryCode
  place_of_birth: string
  percentage_parts: number | null
  street1: string
  street2: string
  zip_code: string
  city: string
  city_of_birth_code: string
  occupation: string
  beneficial_owner: boolean
  legal_representative: boolean
  merchant: boolean
}
export type ServerPersonFieldKey = keyof ServerPersonForm
export type PersonFieldKey = CamelCase<ServerPersonFieldKey>
export interface PersonForm extends Camelize<ServerPersonForm> {}

export const personFieldKeys: { [key in ServerPersonFieldKey]: PersonFieldKey } = {
  first_name: 'firstName',
  last_name: 'lastName',
  gender: 'gender',
  date_of_birth: 'dateOfBirth',
  country_code: 'countryCode',
  country_of_birth_code: 'countryOfBirthCode',
  nationality_country_code: 'nationalityCountryCode',
  place_of_birth: 'placeOfBirth',
  percentage_parts: 'percentageParts',
  street1: 'street1',
  street2: 'street2',
  zip_code: 'zipCode',
  city: 'city',
  city_of_birth_code: 'cityOfBirthCode',
  occupation: 'occupation',
  beneficial_owner: 'beneficialOwner',
  legal_representative: 'legalRepresentative',
  merchant: 'merchant',
}

export const scoringKeys = ['low', 'moderate', 'high', 'very_high', 'forbidden'] as const
export type ScoringKey = (typeof scoringKeys)[number]

export interface Scoring {
  level: ScoringKey
  alert: boolean
}

export interface Person extends PersonForm {
  id: number
  beneficialOwnerIdentityVerificationRequired: boolean
  identityVerification: IdentityVerification
  fields: {
    missing: ServerPersonFieldKey[]
    updatable: ServerPersonFieldKey[]
  }
  permissions: {
    canDelete: boolean
  }
}

export interface FetchPeopleResponseData {
  missingInformation: boolean
  scoring?: Scoring
  companyPeople: Person[]
}

const fetchPeopleQuery = z.number().positive()

export const fetchPeople = async (_companyId: number) => {
  const companyId = fetchPeopleQuery.parse(_companyId)

  const res = await instance.get<FetchPeopleResponseData>(`/companies/${companyId}/people`)

  if (!res.data) return

  return mapData(res.data, true)
}

export interface PatchPersonOptions {
  companyId: number
  personId: string
  person: Partial<PersonForm> | Partial<Snakify<PersonForm>>
}

export const patchPerson = async ({ companyId, personId, person }: PatchPersonOptions) => {
  const res = await instance.patch<FetchPeopleResponseData>(`/companies/${companyId}/people/${personId}`, {
    company_person: mapData(person, false),
  })

  if (!res.data) return

  return mapData(res.data, true)
}

export interface CreatePersonOptions {
  companyId: number
  person: Partial<PersonForm>
}

export const createPerson = async ({ companyId, person }: CreatePersonOptions) => {
  const res = await instance.post<FetchPeopleResponseData>(`/companies/${companyId}/people`, {
    company_person: mapData(person, false),
  })

  if (!res.data) return

  return mapData(res.data, true)
}

export interface VerifyBeneficialOwnerOptions {
  companyId: number
  personId: string
  email: string
}

export const verifyBeneficialOwner = async ({ companyId, personId, email }: VerifyBeneficialOwnerOptions) => {
  const res = await instance.post<FetchPeopleResponseData>(
    `/companies/${companyId}/beneficial_owners/${personId}/identity_verification/email`,
    {
      email,
    },
  )

  if (!res.data) return

  return mapData(res.data, true)
}

export const sendBeneficialOwnerVerificationMail = verifyBeneficialOwner
