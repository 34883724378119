import { useQuery } from '@tanstack/vue-query'
import instance, { mapData } from '@/core/api/client'

import type { ComputedRef } from 'vue'
import type { Amount } from '@/merchant/api/account'
import { z } from 'zod'

export interface TurnoverDay {
  date: string
  turnover: Amount
}

export interface GetPointOfSaleStatsResponseData {
  stats: {
    turnovers: {
      pointOfSale: {
        days: TurnoverDay[]
      }
      checkout?: {
        days: TurnoverDay[]
      }
    }
    customers: {
      days: {
        date: string
        total: number
        new: number
      }[]
      new: number
      total: number
    }
  }
}

export interface GetPointOfSaleStatsOptions {
  companyId: ComputedRef<number>
  pointOfSaleId: ComputedRef<number>
  checkoutId?: ComputedRef<string | undefined>
}

const getPointOfSaleStatsParser = z.object({
  companyId: z.number(),
  pointOfSaleId: z.number(),
  checkoutId: z.string().optional(),
  from: z.date().optional(),
  to: z.date().optional(),
})

export const apiGetPointOfSaleStats = async (_options: z.infer<typeof getPointOfSaleStatsParser>) => {
  const options = getPointOfSaleStatsParser.parse(_options)

  const url = `/companies/${options.companyId}/point_of_sales/${options.pointOfSaleId}/stats/`

  const res = await instance.get<GetPointOfSaleStatsResponseData>(url, {
    params: mapData(
      {
        from: options.from,
        to: options.to,
        checkoutId: options.checkoutId,
      },
      false,
    ),
  })

  return mapData(res.data, true)
}

export const getPointOfSaleStats = (opt: GetPointOfSaleStatsOptions) => {
  const companyId = opt.companyId
  const pointOfSaleId = opt.pointOfSaleId
  const checkoutId = opt.checkoutId
  const from = ref<Date | undefined>()
  const to = ref<Date | undefined>()

  const options = reactive({
    enabled: computed(
      () => !!companyId.value && companyId.value >= 0 && !!pointOfSaleId.value && !!from.value && !!to.value,
    ),
    refetchOnWindowFocus: false,
  })

  const queryKey = reactive(['pointOfSaleStats', { companyId, pointOfSaleId, from, to, checkoutId }])

  const queryFn = async () => {
    const url = `/companies/${companyId.value}/point_of_sales/${pointOfSaleId.value}/stats/`

    const res = await instance.get<GetPointOfSaleStatsResponseData>(url, {
      params: mapData(
        {
          from: from.value,
          to: to.value,
          checkoutId: checkoutId?.value,
        },
        false,
      ),
    })

    return mapData(res.data, true)
  }

  return {
    companyId,
    pointOfSaleId,
    checkoutId,
    from,
    to,
    ...useQuery({
      queryKey,
      queryFn,
      ...options,
    }),
  }
}

export interface PostPointOfSaleClosureOptions {
  companyId: number
  pointOfSaleId: number
}

export const postPointOfSaleClosure = async ({ companyId, pointOfSaleId }: PostPointOfSaleClosureOptions) => {
  const res = await instance.post<boolean>(`/companies/${companyId}/point_of_sales/${pointOfSaleId}/closure`)

  return !!res?.data
}

export const deletePointOfSaleClosure = async ({ companyId, pointOfSaleId }: PostPointOfSaleClosureOptions) => {
  const res = await instance.delete<boolean>(`/companies/${companyId}/point_of_sales/${pointOfSaleId}/closure`)

  return !!res?.data
}
