<script lang="ts" setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

import { Switch } from '@delupay/web-core/components'

import { useRouter } from 'vue-router'

import bus from '@/core/bus/eventBus'

import { useUserStore } from '@/merchant/stores/user'
import { usePeopleStore } from '@/merchant/stores/people'
import { useTestMode } from '@/core/composables/useTestMode'

import { Alert } from '@delupay/web-core/components'

import AccountClosureNotification from '@/merchant/components/user/AccountClosureNotification.vue'
import ImportantNotifications from '@/merchant/components/user/ImportantNotifications.vue'
import OnboardingProgressCard from '@/merchant/components/onboarding/OnboardingProgressCard.vue'
import UserDropdown from '@/merchant/components/user/UserDropdown.vue'

import type { PlayerOptions, ViewerOptions } from '@/core/utils/player'
import MainNav from '../components/MainNav.vue'
import DBackground from '../components/DBackground.vue'
import CustomerServiceModale from '@/merchant/components/user/CustomerServiceModale.vue'
import PointOfSaleSelector from '@/merchant/components/point_of_sales/PointOfSaleSelector.vue'
import { _useUserStore } from '@/merchant/stores/_user'

const { testMode, setTestMode } = useTestMode()
const router = useRouter()
const user = useUserStore()
const _user = _useUserStore()
const {
  currentCompanyId,
  isPointOfSaleSuspended,
  isCompanySuspended,
  anyMissingDocument,
  missingDocuments,
  isProductionModeEnabled,
  isContractSigned,
  isLoading,
} = storeToRefs(user)

const people = usePeopleStore()

onMounted(() => {
  if (currentCompanyId.value !== -2 || !people.loaded) {
    people.init()
  }
})

const showCompaniesDropdown = ref(false)
const dropdown = ref(null)
const showUserDropdown = ref(false)
const showCustomerService = ref(false)
const playerProps = ref<PlayerOptions>({})
const viewerProps = ref<ViewerOptions>({})

const userMenu = ref()
onClickOutside(userMenu, () => {
  showUserDropdown.value = false
})

bus.on('customerservice.show', () => {
  showCustomerService.value = true
})

bus.on('player.show', (props: PlayerOptions) => {
  playerProps.value = props
})

bus.on('viewer.show', (props: ViewerOptions) => {
  viewerProps.value = props
})

onClickOutside(dropdown, () => {
  if (showCompaniesDropdown.value) {
    showCompaniesDropdown.value = false
  }
})

watch(
  currentCompanyId,
  (id) => {
    if (id === -1) {
      router.push({ name: 'companies.create' })
    }
  },
  { immediate: true },
)

whenever(showCustomerService, () => {
  window.dataLayer!.push({ event: 'contact_modal' })
})

const suspension = computed(() => {
  return isCompanySuspended.value || isPointOfSaleSuspended.value
})

onMounted(() => {
  if (!isProductionModeEnabled.value && !testMode) setTestMode(true)
})
</script>

<style>
.test-grid {
  grid-template-columns: auto minmax(0, 1fr) auto;
}
</style>

<template>
  <DBackground />
  <div
    class="relative transition-300 flex flex-col min-h-screen"
    :class="{
      'opacity-100': currentCompanyId !== -1,
      'opacity-0': currentCompanyId === -1,
    }"
  >
    <div class="backface-hidden flex flex-col">
      <header class="z-50 px-2 sm:px-4 py-4 container mx-auto text-white">
        <div class="relative grid grid-cols-5 items-center gap-3">
          <div class="col-span-2">
            <router-link :to="{ name: 'merchant.dashboard' }">
              <delupay-logo class="text-white h-9 w-8" />
            </router-link>
          </div>
          <div class="flex justify-center">
            <PointOfSaleSelector />
          </div>
          <div class="col-span-2 flex justify-end">
            <div class="hidden lg:flex py-2 px-4 rounded-full text-black text-nowrap gap-2">
              <span class="font-semibold text-white">
                Test mode {{ testMode ? $t('common.activated') : $t('common.desactivated') }}</span
              >
              <Switch
                :modelValue="testMode"
                :disabled="!_user.isProductionModeEnabled"
                @update:modelValue="setTestMode($event)"
                class="data-[state=checked]:bg-amber-600 data-[state=unchecked]:bg-grey-400 data-[state=checked]:focus-within:ring-amber-600"
              />
            </div>
            <button
              class="justify-center rounded-md px-2 py-2 text-sm font-medium text-white hover:text-focus focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
              @click="showCustomerService = true"
            >
              <d-icon class="h-5 w-5" icon="phone" size="md" />
            </button>
            <UserDropdown />
          </div>
        </div>
      </header>

      <main class="flex flex-col">
        <div class="container mx-auto px-4 relative flex-grow flex flex-col">
          <Alert variant="warning-light" v-if="testMode" size="sm" class="mb-4">
            <IconCircleInfo class="h-5 w-5" />
            <span class="ml-2"> Test mode {{ $t('common.activated') }}</span>
          </Alert>
          <ImportantNotifications v-if="!user.closureConfirmedAt" class="mb-4" />
          <AccountClosureNotification v-else class="mb-4" />
          <OnboardingProgressCard v-if="!isLoading && !isContractSigned" class="mb-4" />
          <MissingDocumentMessage
            v-if="anyMissingDocument && isProductionModeEnabled"
            class="mb-4"
            :missing-documents="missingDocuments"
            @click="showCustomerService = true"
          />
          <SuspentionMessage v-if="suspension" class="mb-4" @click="showCustomerService = true" />
          <HighScoringMessage
            v-if="
              isContractSigned &&
              !isProductionModeEnabled &&
              ['high', 'very_high', 'forbidden'].includes(people.scoring?.level || '')
            "
            class="mb-4"
            @click="showCustomerService = true"
            :level="people.scoring.level"
          />
        </div>
        <div
          :key="testMode ? 'test' : 'prod'"
          class="container mx-auto px-4 relative flex-grow flex flex-col mb-16 transition-all"
        >
          <RouterView />
        </div>
      </main>
    </div>

    <Teleport to="body">
      <CustomerServiceModale v-model="showCustomerService" />
    </Teleport>

    <d-image-viewer v-bind="viewerProps" @close="viewerProps = {}" />
    <d-video-player v-bind="playerProps" @close="playerProps = {}" />

    <div class="fixed bottom-0 w-screen z-10 flex flex-col">
      <MainNav class="bg-grey-50 text-dark px-2 h-[3.125rem] flex items-center justify-center" />
    </div>
  </div>
</template>

<style scoped>
.backface-hidden {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
</style>
