<script lang="ts" setup>
import type { Icons } from '../utils/icons'
import type { AppColor } from '@/core/utils/colors'

withDefaults(
  defineProps<{
    icon?: Icons
    showIcon?: boolean
    centerIcon?: boolean
    light?: boolean
    color?: AppColor
    variant?: 'base' | 'border'
    iconSize?: 'sm' | 'base' | 'md' | 'lg' | string
    iconColor?: AppColor
    canDiscard?: boolean
  }>(),
  {
    variant: 'base',
    icon: 'circle-info',
    showIcon: true,
    centerIcon: false,
    light: true,
    color: 'dark',
    iconSize: 'md',
  },
)

const emit = defineEmits(['discard'])
</script>

<template>
  <div
    class="rounded-lg p-4 text-base relative"
    :class="[
      {
        'bg-opacity-20': light,
        'text-base': !light && color === 'warning',
        'text-white': !light && color !== 'warning',
        'border-1': variant === 'border',
        'text-white border-white': light && variant === 'border',
        'text-dark border-dark': !light && variant === 'border',
      },
      variant === 'base' ? `bg-${color}` : null,
      light && variant === 'base' ? `text-${color}` : null,
    ]"
  >
    <div
      class="flex gap-4 w-full text-sm"
      :class="{
        'items-start': !centerIcon,
        'items-center': centerIcon,
      }"
    >
      <d-icon
        v-if="showIcon"
        :icon="icon"
        :size="iconSize"
        :class="[iconColor ? `text-${iconColor}` : null, 'flex-none']"
      />
      <slot />
    </div>
    <d-button
      v-if="canDiscard"
      icon="close"
      size="xs"
      variant="text"
      class="!absolute right-1 top-1"
      :color="light ? 'dark' : 'white'"
      @click="emit('discard')"
    />
  </div>
</template>
