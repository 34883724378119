import { defineStore, storeToRefs } from 'pinia'

import { manageStore } from '@/merchant/utils/storeListManager'
import { useUserStore } from '@/merchant/stores/user'

import type { Ref, ComputedRef } from 'vue'
import type { ListStore } from '@/merchant/utils/storeListManager'
import {
  deleteDocument,
  getDocuments,
  postDocument,
  documentTypeIsSubstantiating,
  documentTypeIsStatus
} from '@/merchant/api/documents'

import type {
  DeleteDocumentOptions,
  GetDocumentsResponse,
  PostDocumentOptions,
  Document,
  DocumentType,
  SubstantiatingDocumentType
} from '@/merchant/api/documents'

export interface AddDocumentParams extends Omit<PostDocumentOptions, 'companyId'> {}
export interface RemoveDocumentParams extends Omit<DeleteDocumentOptions, 'companyId'> {}

export interface DocumentStore extends Omit<ListStore, 'list'> {
  items: Ref<{ [id: number]: Document }>
  list: Ref<number[]>
  substantiating: ComputedRef<number[]>
  status: ComputedRef<Document | undefined>
  availableSubstantiatingDocumentTypes: ComputedRef<DocumentType[]>
  get: () => Promise<boolean>
  add: (opt: AddDocumentParams) => Promise<boolean>
  remove: (opt: RemoveDocumentParams) => Promise<boolean>
  init: () => void
  clear: () => void
  loaded: ComputedRef<boolean>
}

export const useDocumentStore = defineStore('substantiatingDocument', (): DocumentStore => {
  const user = useUserStore()
  const { currentCompanyId } = storeToRefs(user)
  const items = ref<{ [id: number]: Document }>({})
  const list = ref<number[]>([])
  const loading = ref<boolean>(false)
  const metaDataSubstantiatingDocumentTypes = ref<DocumentType[]>([])

  const loaded = computed(() => !!metaDataSubstantiatingDocumentTypes.value.length && !loading.value)

  const substantiating = computed(() => list.value.filter((i) => documentTypeIsSubstantiating(items.value[i].type)))

  const status = computed(() => {
    const key = list.value.find((i) => documentTypeIsStatus(items.value[i].type))
    if (!key) return
    return items.value[key]
  })

  const availableSubstantiatingDocumentTypes = computed(() => {
    const ordered: SubstantiatingDocumentType[] = [
      'tax_return',
      'estimated_budget',
      'certified_register_of_shareholder',
      'annual_report',
      'other_company_proof'
    ]
    return ordered.filter((t) => metaDataSubstantiatingDocumentTypes.value.includes(t))
  })

  const get = () =>
    manageStore({
      loading,
      request: () => getDocuments({ companyId: currentCompanyId.value }),
      success: ({ documents, metadata }: GetDocumentsResponse) => {
        list.value.splice(0, list.value.length)
        documents.forEach((n) => {
          items.value[n.id] = n
          list.value.push(n.id)
        })
        metaDataSubstantiatingDocumentTypes.value.splice(0, metaDataSubstantiatingDocumentTypes.value.length)
        metadata.substantiatingDocumentTypes.forEach((d) => {
          metaDataSubstantiatingDocumentTypes.value.push(d)
        })
      }
    })

  const add = ({ type, file, title }: AddDocumentParams) =>
    manageStore({
      loading,
      request: () =>
        postDocument({
          companyId: currentCompanyId.value,
          type,
          file,
          title
        }),
      success: (doc: Document) => {
        list.value.push(doc.id)
        items.value[doc.id] = doc
      }
    })

  const remove = ({ id }: RemoveDocumentParams) =>
    manageStore({
      loading,
      request: () => deleteDocument({ companyId: currentCompanyId.value, id }),
      success: () => {
        list.value.splice(list.value.indexOf(id), 1)
        delete items.value[id]
      }
    })

  const init = () => {
    if (list.value.length || loading.value) return
    get()
  }

  const clear = () => {
    list.value.forEach((id) => delete items.value[id])
    list.value.splice(0, list.value.length)
  }

  return {
    items,
    list,
    loading,
    loaded,
    substantiating,
    status,
    availableSubstantiatingDocumentTypes,
    get,
    init,
    clear,
    add,
    remove
  }
})
