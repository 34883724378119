<template>
  <d-input
    :id="id"
    v-model="localValue"
    :type="showPassword ? 'text' : 'password'"
    :label="label"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :valid="valid"
    minlength="15"
    passwordrules="minlength: 15; required: lower; required: upper; required: digit; required: [-+_!@#$%^&*.,?];"
    v-bind="$attrs"
    required
    :icon="showPassword ? 'base' : 'eye'"
    icon-interactive
    @click:icon="showPassword = !showPassword"
  />
</template>

<script lang="ts" setup>
const showPassword = ref(false)
interface PasswordInputProps {
  modelValue?: string
  label?: string
  placeholder?: string
  id?: string
  autocomplete?: string
  valid?: boolean
}
const props = withDefaults(defineProps<PasswordInputProps>(), {
  valid: undefined,
})
const emit = defineEmits(['update:modelValue'])

const localValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})
</script>
