import { useQuery } from '@tanstack/vue-query'

import instance, { mapData } from '@/core/api/client'

import type { AccountHistoryFrequency } from '@/merchant/api/histories'
import type { Currency } from './transaction'
import type { Item } from './common'
import type { Country } from '@/core/utils/countries'
import type { IdentityVerification } from '@/merchant/api/company'

export interface Badge {
  title: string
  pictureUrl: string
}

export interface Amount {
  raw: number
  localized: string
}

export interface SponsorshipCampaignScenario {
  banner: string
  sponsorAmount: string
  sponsoredAmount: string
  sponsorCommissionRate: string
  sponsoredCommissionRate: string
  badges: Badge[]
}

export interface Account {
  externalIban: string
  externalBank: string
  transferFrequency: Item
  balance: Amount
  overdraftLimit: Amount
  currency: Currency
  badges: Badge[]
  sponsorship: {
    token: string
    campaign: {
      startedAt: string
      finishedAt: string
      company: SponsorshipCampaignScenario
      customer: SponsorshipCampaignScenario
    }
    sponsor: {
      companyTitle: string
      firstName: string
      lastName: string
    }
  }
  permissions: {
    canAddSponsor: boolean
  }
  historyFrequency: AccountHistoryFrequency
  pendingExternalBankAccountUpdate?: {
    bank: string
    createdAt: Date
    ibanCountry: Country
    maskedIban: string
    identityVerification?: IdentityVerification
  }
}

export interface GetAccountResponseData {
  account: Account
}

export interface GetAccountOptions {
  companyId: number
  pointOfSaleId: number
}

export const getAccount = async ({ companyId, pointOfSaleId }: GetAccountOptions): Promise<Account | undefined> => {
  const res = await instance.get<GetAccountResponseData>(
    `/companies/${companyId}/point_of_sales/${pointOfSaleId}/account`,
  )

  if (!res?.data) return

  return mapData(res.data, true).account
}

export interface PatchAccountOptions {
  account: {
    externalIban?: string
    transferFrequencyId?: number
    historyFrequency?: AccountHistoryFrequency
  }
  pointOfSaleId: number
  companyId: number
}

export interface PatchAccountResponseData {
  account: Account
}

export const patchAccount = async ({ account, companyId, pointOfSaleId }: PatchAccountOptions) => {
  let updatedAccount: Account | undefined

  try {
    const res = await instance.patch(`companies/${companyId}/point_of_sales/${pointOfSaleId}/account`, {
      account: mapData(account, false),
    })

    if (!res?.data) return

    updatedAccount = mapData(res.data, true).account
  } catch (err) {
    console.error(err)
  }

  return updatedAccount
}

interface GetTransferFrequenciesResponseData {
  listItems: Item[]
}

export const apiGetTransferFrequencies = async () => {
  const res = await instance.get<GetTransferFrequenciesResponseData>('/transfer_frequencies')

  return mapData(res.data, true)
}

export const getTransferFrequencies = () => {
  const options = reactive({
    enabled: true,
    refetchOnWindowFocus: false,
  })

  const queryKey = reactive(['transferFrequencies'])

  return {
    ...useQuery({
      queryKey,
      queryFn: apiGetTransferFrequencies,
      ...options,
    }),
  }
}

interface PostAccountIdentityVerificationOptions extends GetAccountOptions {
  password: string
}

export const postAccountIdentityVerification = async ({
  companyId,
  pointOfSaleId,
  password,
}: PostAccountIdentityVerificationOptions) => {
  const res = await instance.post(
    `/companies/${companyId}/point_of_sales/${pointOfSaleId}/account/external_bank_account_update_identity_verification`,
    {
      password,
    },
  )

  if (!res?.data) return
  // @NOTE: I have forced the type to be Account, but we should get the true type from the API... (but it's not documented)
  return mapData(res.data, true).account as { account: Account }
}

interface PostAccountIdentityVerificationEmailOptions extends GetAccountOptions {
  email: string
}

export const postAccountIdentityVerificationEmail = async ({
  companyId,
  pointOfSaleId,
  email,
}: PostAccountIdentityVerificationEmailOptions): Promise<{ account: Account } | undefined> => {
  const res = await instance.post(
    `/companies/${companyId}/point_of_sales/${pointOfSaleId}/account/external_bank_account_update_identity_verification/email`,
    { email },
  )

  if (!res?.data) return
  // @NOTE: I have forced the type to be Account, but we should get the true type from the API... (but it's not documented)
  return mapData(res.data, true).account as { account: Account }
}

export type BankAccountState = 'pending' | 'active' | 'disabled'
export interface BankAccount {
  id: number
  state: BankAccountState
  maskedIban: string
  bic: string
  ibanCountryCode: string
  bank: string
  confirmedAt: Date
  createdAt: Date
  updatedAt: Date
}

export const getExternalBankAccounts = async ({ companyId, pointOfSaleId }: GetAccountOptions) => {
  const res = await instance.get<{ externalBankAccountUpdates: BankAccount[] }>(
    `/companies/${companyId}/point_of_sales/${pointOfSaleId}/account/external_bank_account_updates`,
  )

  if (!res?.data) return

  return mapData(res.data, true).externalBankAccountUpdates
}

export const deleteBankAccountUpdate = async ({ companyId, pointOfSaleId }: GetAccountOptions) => {
  const res = await instance.delete<boolean>(
    `/companies/${companyId}/point_of_sales/${pointOfSaleId}/account/external_bank_account_update`,
  )

  return !!res?.data
}
