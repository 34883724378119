import instance, { mapData } from '@/core/api/client'
import { captureException } from '@sentry/vue'
import { z } from 'zod'

const substantiatingDocumentTypes = [
  'estimated_budget',
  'tax_return',
  'certified_register_of_shareholder',
  'annual_report',
  'other_company_proof',
] as const
export type SubstantiatingDocumentType = (typeof substantiatingDocumentTypes)[number]

export type DocumentType = SubstantiatingDocumentType | 'status'

export interface Document {
  id: number
  type: DocumentType
  title: string
  createdAt: string
}

export const documentTypeIsSubstantiating = (type: DocumentType): type is SubstantiatingDocumentType =>
  substantiatingDocumentTypes.includes(type as SubstantiatingDocumentType)

export const documentTypeIsStatus = (type: DocumentType): type is 'status' => type === 'status'

interface GetDocumentsOptions {
  companyId: number
}

export interface GetDocumentsResponse {
  documents: Document[]
  metadata: {
    substantiatingDocumentTypes: SubstantiatingDocumentType[]
  }
}

export const getDocuments = async ({ companyId }: GetDocumentsOptions) => {
  const res = await instance.get<GetDocumentsResponse>(`companies/${companyId}/documents`)

  if (!res?.data) return

  return mapData(res.data, true)
}

export interface PostDocumentOptions {
  companyId: number
  type: DocumentType
  file?: File
  title?: string
}

interface PostDocumentResponse {
  document: Document
}

export const postDocument = async ({ companyId, type, file, title }: PostDocumentOptions) => {
  if (!file) return new Promise(() => true).then(() => undefined)

  const res = await instance.postForm<PostDocumentResponse>(
    `/companies/${companyId}/documents`,
    {
      document: {
        file: file,
        type: type,
        ...(title ? { title: title } : {}),
      },
    },
    {
      headers: {
        // Here we are forcing the content type to be multipart/form-data
        // event if the method is postForm cause it's been overriden in the instance
        'Content-Type': 'multipart/form-data',
      },
    },
  )

  if (!res?.data) return

  return mapData(res.data, true).document
}

export interface DeleteDocumentOptions {
  companyId: number
  id: number
}

export const deleteDocument = async ({ companyId, id }: DeleteDocumentOptions) => {
  const res = await instance.delete<boolean>(`/companies/${companyId}/documents/${id}`)

  return !!res?.data
}

/**
 * New document api
 *
 * please remove the api above if you've remove all the old document api implementation
 */

const baseDocumentParamsParser = z.object({
  company_id: z.number(),
})

export const getDocumentsParamsParser = baseDocumentParamsParser

export type _GetDocumentsOptions = z.infer<typeof getDocumentsParamsParser>

/**
 * Get documents
 *
 * @param {Object} _options
 * @param {number} _options.company_id - The company ID
 */
export const apiGetDocuments = async (_options: _GetDocumentsOptions) => {
  const options = baseDocumentParamsParser.parse(_options)

  try {
    const { data } = await instance.get<GetDocumentsResponse>(`companies/${options.company_id}/documents`)

    return data
  } catch (error) {
    if (import.meta.env.DEV) console.error(error)
    captureException(error)
  }
}

export const getDocumentParamsParser = baseDocumentParamsParser.extend({
  id: z.number(),
})

export type _GetDocumentOptions = z.infer<typeof getDocumentParamsParser>

/**
 * Get a document
 *
 * @param {Object} _options
 * @param {number} _options.company_id - The company ID
 * @param {number} _options.id - The document ID
 */
export const apiGetDocument = async (_options: _GetDocumentsOptions) => {
  const options = getDocumentParamsParser.parse(_options)

  try {
    const { data } = await instance.get<GetDocumentsResponse>(`companies/${options.company_id}/documents/${options.id}`)

    return data
  } catch (error) {
    if (import.meta.env.DEV) console.error(error)
    captureException(error)
  }
}

export const postDocumentParamsParser = baseDocumentParamsParser.extend({
  type: z.string(),
  file: z.instanceof(File),
  title: z.string().optional(),
  comment: z.string().optional(),
})

export type _PostDocumentOptions = z.infer<typeof postDocumentParamsParser>

/**
 * Post a document
 *
 * @param {Object} _options
 * @param {number} _options.company_id - The company ID
 * @param {string} _options.type - The document type
 * @param {File} _options.file - The document file
 * @param {string} _options.title - The document title
 * @param {string} _options.comment - The document comment
 */
export const apiPostDocument = async (_options: _PostDocumentOptions) => {
  const options = postDocumentParamsParser.parse(_options)

  try {
    const { data } = await instance.post<PostDocumentResponse>(
      `companies/${options.company_id}/documents`,
      {
        document: {
          type: options.type,
          file: options.file,
          ...(options.title ? { title: options.title } : {}),
          ...(options.comment ? { comment: options.comment } : {}),
        },
      },
      {
        headers: {
          // Here we are forcing the content type to be multipart/form-data
          // event if the method is postForm cause it's been overriden in the instance
          'Content-Type': 'multipart/form-data',
        },
      },
    )

    return data.document
  } catch (error) {
    if (import.meta.env.DEV) console.error(error)
    captureException(error)
  }
}

export const postCommissionDocumentParamsParser = baseDocumentParamsParser.extend({
  type: z.enum(['online_commission_rate', 'offline_commission_rate']),
  file: z.instanceof(File),
  title: z.string().optional(),
  comment: z.string().optional(),
})

export type _PostCommissionDocumentOptions = z.infer<typeof postCommissionDocumentParamsParser>

/**
 * Post a commission document
 *
 * @param {Object} _options
 * @param {number} _options.company_id - The company ID
 * @param {string} _options.type - The document type
 * @param {File} _options.file - The document file
 * @param {string} _options.title - The document title
 * @param {string} _options.comment - The document comment
 */
export const apiPostCommissionDocument = async (_options: _PostCommissionDocumentOptions) => {
  const options = postDocumentParamsParser.parse(_options)

  try {
    const { data } = await instance.post<PostDocumentResponse>(
      `/companies/${options.company_id}/documents/create_commission`,
      {
        document: {
          type: options.type,
          file: options.file,
          ...(options.title ? { title: options.title } : {}),
          ...(options.comment ? { comment: options.comment } : {}),
        },
      },
      {
        headers: {
          // Here we are forcing the content type to be multipart/form-data
          // event if the method is postForm cause it's been overriden in the instance
          'Content-Type': 'multipart/form-data',
        },
      },
    )

    return data.document
  } catch (error) {
    if (import.meta.env.DEV) console.error(error)
    captureException(error)
  }
}

export const deleteDocumentParamsParser = baseDocumentParamsParser.extend({
  id: z.number(),
})

export type _DeleteDocumentOptions = z.infer<typeof deleteDocumentParamsParser>

/**
 * Delete a document
 *
 * @param {Object} _options
 * @param {number} _options.company_id - The company ID
 * @param {number} _options.id - The document ID
 */
export const apiDeleteDocument = async (_options: _DeleteDocumentOptions) => {
  const options = deleteDocumentParamsParser.parse(_options)

  try {
    const { data } = await instance.delete<boolean>(`/companies/${options.company_id}/documents/${options.id}`)

    return data
  } catch (error) {
    if (import.meta.env.DEV) console.error(error)
    captureException(error)
  }
}
