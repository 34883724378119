import { computed, ref } from 'vue'
import { z } from 'zod'

import { useAuthStore } from '@/merchant/stores/auth'

import {
  login as apiLogin,
  logout as apiLogout,
  register as apiRegister,
  type AuthToken,
  type LoginOptions,
} from '@/core/api/auth'
import { useUserStore } from '@/merchant/stores/user'
import { getUserInfos } from '@/merchant/api/registration'

class AuthError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'AuthError'
  }
}

// @NOTE: this is temporary not typed.
export const userParser = z.object({
  uuid: z.string(),
  email: z.string().email(),
  first_name: z.string(),
  last_name: z.string(),
  mobile_phone_number: z.string(),
  avatar_url: z.string(),
  locale: z.union([z.literal('fr'), z.literal('en')]),
  company_id: z.number().nullable(),
  point_of_sales: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      checkout_number: z.string(),
      company: z.object({
        id: z.number(),
        name: z.string(),
        logo_url: z.string(),
      }),
    }),
  ),
})

type User = z.infer<typeof userParser>

const user = ref<User | null>(null)

export const _setToken = (token: AuthToken) => {
  localStorage.setItem('bearer_token', token.access_token)
  localStorage.setItem('expires_at', (token.created_at * 1000 + token.expires_in * 1000).toString())
}

export const _deleteToken = () => {
  localStorage.removeItem('bearer_token')
  localStorage.removeItem('expires_at')
}

export const useAuth = () => {
  const auth = useAuthStore()
  const userStore = useUserStore()

  const { activePointOfSaleId } = userStore

  const login = async (options: LoginOptions) => {
    try {
      const res = await apiLogin(options)

      _setToken(res.token)

      return Promise.resolve()
    } catch (err) {
      return Promise.reject(new AuthError('Unable to login'))
    }
  }

  const registerParser = z.object({
    email: z.string().email(),
    first_name: z.string(),
    last_name: z.string(),
    password: z.string().min(8),
    mobile_phone_number: z.string(),
    mobile_phone_number_country_code: z.string(),
    analytics_trackers: z.record(z.string()),
    sponsorship_token: z.string().optional(),
    partner_token: z.string().optional(),
  })

  type RegisterOptions = z.infer<typeof registerParser>

  const register = async (options: RegisterOptions) => {
    const payload = registerParser.parse(options)

    const res = await apiRegister({
      partner_token: payload.partner_token,
      registration: {
        email: payload.email,
        first_name: payload.first_name,
        last_name: payload.last_name,
        password: payload.password,
        mobile_phone_number: payload.mobile_phone_number,
        mobile_phone_number_country_code: payload.mobile_phone_number_country_code,
        analytics_trackers: payload.analytics_trackers,
      },
    })

    if (res.metadata?.token) {
      _setToken(res.metadata.token)
    }
  }

  const logout = async () => {
    await apiLogout()
    _deleteToken()
    auth.logout()
  }

  const check = async () => {
    const token = localStorage.getItem('bearer_token')

    if (!token) {
      return Promise.reject(new AuthError('No token found'))
    }

    if (Date.now() > Number(localStorage.getItem('expires_at'))) {
      localStorage.removeItem('bearer_token')
      localStorage.removeItem('expires_at')
      return Promise.reject(new AuthError('Token expired'))
    }

    const { registration } = await getUserInfos()

    const newActiveCompanyId = registration?.pointOfSales?.length > 0 ? registration.pointOfSales[0].companyId : -1
    const newActivePointOfSaleId = registration?.pointOfSales?.length > 0 ? registration.pointOfSales[0].id : -1
    const pointOfSaleId =
      activePointOfSaleId && registration?.pointOfSales?.some((pos) => pos.id === activePointOfSaleId)
        ? activePointOfSaleId
        : newActivePointOfSaleId
    const companyId =
      registration?.pointOfSales?.find((pos) => pointOfSaleId === pos.id)?.companyId ?? newActiveCompanyId

    const _user = userStore.set(registration, pointOfSaleId, companyId)

    const parse = userParser.safeParse(_user)

    if (parse.success) user.value = parse.data

    return _user
  }

  const isLoggedIn = computed(() => !!user.value)

  return {
    login,
    register,
    logout,
    check,
    isLoggedIn,
  }
}
