import instance, { mapData } from '@/core/api/client'

import type { Amount } from '@/merchant/api/transaction'

export const overdueNotificationKeys = [
  'overdraft_detected',
  'debit_issued',
  'debit_rejected',
  'overdraft_recovered',
] as const
export type OverdueNotificationKey = (typeof overdueNotificationKeys)[number]
export const disputeNotificationKeys = [
  'dispute_created',
  'dispute_accepted',
  'dispute_refused',
  'dispute_confirmed',
] as const
export type DisputeNotificationKey = (typeof disputeNotificationKeys)[number]

export const notificationKeys = [...overdueNotificationKeys, ...disputeNotificationKeys, 'message_created'] as const
export type NotificationKey = (typeof notificationKeys)[number]
export type ImportantNotificationKey = Extract<
  NotificationKey,
  'overdraft_detected' | 'debit_issued' | 'debit_rejected' | 'overdraft_recovered'
>
export const importantNotificationKeys: ImportantNotificationKey[] = [
  'overdraft_detected',
  'debit_issued',
  'debit_rejected',
  'overdraft_recovered',
]

export type CanDiscardNotification = Extract<ImportantNotificationKey, 'overdraft_recovered'>
export const canDiscardNotifications = ['overdraft_recovered']

export interface SystemNotificationData {
  overdraft?: Amount
  recoveryIban?: string
}

export interface SystemNotification {
  uuid: string
  key: NotificationKey
  companyId: number | null
  pointOfSaleId: number | null
  data: SystemNotificationData | null
}

export interface GetSystemNotificationsResponse {
  systemNotifications: SystemNotification[]
}

export const isImportantNotificationKey = (key: NotificationKey): key is ImportantNotificationKey =>
  importantNotificationKeys.includes(key as ImportantNotificationKey)

export const getSystemNotifications = async () => {
  const res = await instance.get<{ systemNotifications: SystemNotification[] }>('/system_notifications')

  if (!res?.data) return

  return mapData(res.data, true).systemNotifications
}

export const setSystemNotificationRead = async (uuid: string) => {
  const res = await instance.post(`/system_notifications/${uuid}/reading`)

  return !!res?.data
}
