<template>
<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M.5 7.357h6.857V.5H.5v6.857Zm2.286-2.285H5.07V2.786H2.786v2.286ZM16.5 7.357H9.643V.5H16.5v6.857Zm-2.286-2.285h-2.286V2.786h2.286v2.286Z"
      fill="currentColor"
    />
    <path
      d="M11.928 11.929H9.643V9.643h2.285v2.286ZM14.214 14.214h-2.286V11.93h2.286v2.285ZM14.214 11.929H16.5V9.643h-2.286v2.286ZM11.929 16.5H9.643v-2.286h2.285V16.5ZM14.214 16.5H16.5v-2.286h-2.286V16.5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.357 16.5H.5V9.643h6.857V16.5Zm-2.286-2.286H2.786V11.93H5.07v2.285Z"
      fill="currentColor"
    />
  </svg>
</template>
