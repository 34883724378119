<script setup lang="ts" generic="T">
import type { HTMLAttributes } from 'vue'
import { type InputVariants, inputVariants } from '.'
import { cn } from '@/lib/utils'

const props = defineProps<{
  size?: InputVariants['size']
  defaultValue?: T
  modelValue?: T
  class?: HTMLAttributes['class']
}>()

defineEmits<{
  'update:modelValue': [value: T]
}>()

const model = defineModel<T>()
</script>

<template>
  <input v-model="model" :class="cn(inputVariants({ size }), props.class)" />
</template>
