export const hasLength = (str?: string): boolean => !!str && str.length > 0

export const hasTrimmedLength = (str: string): boolean => hasLength(str.trim())

export const containsOneLowerCase = (str: string): boolean => hasLength(str) && /(?=.*[a-z])/.test(str)

export const containsOneUpperCase = (str: string): boolean => hasLength(str) && /(?=.*[A-Z])/.test(str)

export const containsOneDigit = (str: string): boolean => hasLength(str) && /\d/.test(str)

export const symbolRegex = new RegExp('[!"#$%&\'()*+,-./:;<=>?@\\[\\]^_`{|}~]')
export const containsOneSymbol = (str: string): boolean => hasLength(str) && symbolRegex.test(str)

export const emailIsValid = (str: string): boolean =>
  hasLength(str) &&
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    str,
  )

export const spaceThousands = (str: string): string => {
  const int = str.split('.')[0]
  const dot = str.includes('.') ? '.' : ''
  if (int.length < 4) return str
  const arr = int
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g)
  if (!arr) return str
  return `${arr.join(' ').split('').reverse().join('')}${dot}${str.split('.')[1] || ''}`
}
