import { addDays, addYears, endOfDay, format, isAfter, isDate, isFuture, isToday, parseISO, startOfDay } from 'date-fns'

import fr from 'date-fns/locale/fr'

import { rangePresetDef } from '@/types'

import type { PresetKey, Range } from '@/types'

export const formatISO = (isoDate: string, pattern: string, code = 'fr') =>
  format(parseISO(isoDate), pattern, code === 'fr' ? { locale: fr, weekStartsOn: 1 } : undefined)

export const remainingTime = (time: number) => {
  if (time < 3600) return `${Math.floor(time / 60)}m`
  if (time < 86400) return `${Math.floor(time / 3600)}h`
  return `${Math.floor(time / 86400)}j`
}

interface DayStringOptions {
  pattern?: string
  code?: string
  short?: boolean
}

const defaultDayStringOptions = { pattern: 'dd.MM.yyyy', code: 'fr', short: false }

export const formattedDayString = (isoDate: string, opt: DayStringOptions = {}) => {
  const { pattern, code, short } = { ...defaultDayStringOptions, ...opt }
  const then = new Date(isoDate).getTime()
  if (isToday(then)) return short ? 'Auj.' : `Aujourd'hui`
  if (isToday(addDays(then, 1))) return 'Hier'
  return formatISO(isoDate, pattern, code)
}

export const getPresetRange = (key: PresetKey): Range | undefined => {
  const offset = rangePresetDef[key]
  if (!offset) return
  const d = new Date()
  return {
    from: addDays(startOfDay(d), offset[0]),
    to: addDays(endOfDay(d), offset[1])
  }
}

export const realisticBirthDate = (date?: string) => {
  if (!date) return
  const d = new Date(date)
  const n = Date.now()
  return isDate(d) && !isFuture(d) && isAfter(d, addYears(n, -150))
}
