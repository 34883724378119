import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

import 'dayjs/locale/fr'
import 'dayjs/locale/en'

import relativeTime from 'dayjs/plugin/relativeTime'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'

dayjs.extend(relativeTime)
dayjs.extend(isToday)
dayjs.extend(isYesterday)

export const useDayjs = () => {
  const { locale } = useI18n()

  dayjs.locale(locale.value)

  return { dayjs }
}

export default useDayjs
