import { useMutation } from '@tanstack/vue-query'
import instance, { mapData } from '@/core/api/client'

import type { Company } from './company'

export interface CreateContractSignatureRequest {
  companyId: number
}

export interface CreateContractSignatureResponseData {
  company: Company
}

export const createContractSignature = () => {
  const mutationFn = async (payload: CreateContractSignatureRequest): Promise<CreateContractSignatureResponseData> => {
    const res = await instance.post(`companies/${payload.companyId}/contract/signature`)

    return mapData(res.data, true)
  }

  return useMutation<CreateContractSignatureResponseData, unknown, CreateContractSignatureRequest, unknown>({
    mutationFn,
  })
}

export type SignedDocumentType = 'contract' | 'terms'

interface getSignedDocumentOptions {
  companyId: number
  type: SignedDocumentType
}

export const getSignedDocument = async (opt: getSignedDocumentOptions) => {
  const { companyId, type } = opt

  const res = await instance.get(`/companies/${companyId}/${type}`, { responseType: 'blob' })

  return mapData(res.data, true)
}

interface getSignedSepaMandateOptions {
  companyId: number
  pointOfSaleId: number
}

export const getSignedSepaMandate = async (opt: getSignedSepaMandateOptions) => {
  const { companyId, pointOfSaleId } = opt

  const res = await instance.get(`/companies/${companyId}/point_of_sales/${pointOfSaleId}/sepa_mandate`, {
    responseType: 'blob',
  })

  return mapData(res.data, true)
}
