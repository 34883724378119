import mitt from 'mitt'

import type { ApiErrorResponse } from '../api/client'
import type { PlayerOptions, ViewerOptions } from '@/core/utils/player'
import type { Transaction } from '@/merchant/api/transaction'
import type { Emitter } from 'mitt'

export interface PromptOpenEvent {
  id: string
  description?: string
  title?: string
  inputType?: 'text' | 'number' | 'password' | 'textarea' | 'email'
  submitText?: string
  inputValue?: string | number
  data?: any
  placeholder?: string
  submitType?: string
  required?: boolean
  disabledCheck?: (val: string | undefined) => boolean
  format?: (val: string) => string
  label?: string
  cancelText?: string | null
  canClose?: boolean
}

export interface PromptCloseEvent {
  value: string | number | null
  id: string
  action: 'cancel' | 'confirm'
}

type Events = {
  'api:message': string
  'api:errors': ApiErrorResponse
  'session.expired': void
  'transaction.created': Transaction
  'transaction.params.reset': void
  'transaction.association.create': Transaction
  'checkout.detail': string
  'prompt.open': PromptOpenEvent
  'prompt.close': PromptCloseEvent
  'modal.transaction.create': void
  'modal.open': void
  'modal.close': void
  'customerservice.show': void
  'player.show': PlayerOptions
  'viewer.show': ViewerOptions
}

const bus: Emitter<Events> = mitt<Events>()
export const modals = { count: 0 }
export default bus
