import { type VariantProps, cva } from 'class-variance-authority'

export { default as Button } from './Button.vue'

export const buttonVariants = cva(
  'overflow-hidden font-semibold shadow-sm transition-all inline-flex items-center justify-center cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed dp-focus',
  {
    variants: {
      variant: {
        none: 'shadow-none',
        primary: 'bg-dp-600 text-white hover:bg-dp-700',
        'primary-alt': 'bg-dp-50 text-dp-500 hover:bg-dp-100',
        secondary: 'bg-grey-100 text-grey-900 hover:bg-grey-200',
        white:
          'bg-grey-50 hover:bg-gray-200 hover:disabled:bg-grey-50 text-black hover:text-expose focus:text-dp-600 shadow-none',
        'outline-white':
          'border border-grey-100 bg-grey-50 hover:bg-gray-200 hover:disabled:bg-grey-50 text-black hover:text-expose focus:text-dp-600 shadow-none',
        green: 'bg-green-500 text-white hover:bg-green-600 focus:bg-green-600',
        'green-inverted': 'bg-green-100 text-green-600 hover:bg-green-200 focus:bg-green-200',
        'outline-green': 'border border-green-500 text-green-500 ',
        red: 'bg-red-500 text-white hover:bg-red-600 focus:bg-red-600',
        'outline-red':
          'border border-red-500 text-red-500 hover:text-white hover:bg-red-500 focus:bg-red-500 focus:text-white',
        border:
          'border border-dp-700 text-dp-700 hover:text-white hover:border-focus hover:text-focus focus:bg-dp-700 focus:text-white',
        blue: 'bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600',
      },
      size: {
        xxs: 'rounded-lg px-1 py-0.5 text-xxs',
        xs: 'rounded-lg px-2 py-1 text-xs',
        sm: 'rounded-lg px-2 py-2 text-sm',
        default: 'rounded-lg px-3 py-2',
        lg: 'rounded-xl px-3.5 py-3',
        'icon-xs': 'rounded-lg p-1',
        'icon-sm': 'rounded-lg p-1.5',
        icon: 'rounded-lg p-2',
        'icon-lg': 'rounded-lg p-3',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  },
)

export type ButtonVariants = VariantProps<typeof buttonVariants>
