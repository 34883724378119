<script setup lang="ts">
import Icon from '@/components/ui/icon/Icon.vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

import Button from '@/components/ui/button/Button.vue'

const emit = defineEmits(['update:modelValue', 'close'])

const sizes = ['sm', 'base', 'lg', 'xl', '2xl', '3xl'] as const

const props = withDefaults(
  defineProps<{
    modelValue: boolean
    size?: (typeof sizes)[number]
    noAutoClose?: boolean
    noPadding?: boolean
    noBg?: boolean
  }>(),
  {
    size: 'base',
  },
)

const handleClose = () => {
  if (props.noAutoClose) return
  emit('update:modelValue', false)
  emit('close')
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-50 transition-all" @close="handleClose" v-bind="$attrs">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed bottom-0 xs:inset-0 z-10 w-screen overflow-y-auto xs:flex xs:justify-center xs:items-center">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 xs:translate-y-0 xs:scale-95"
          enter-to="opacity-100 translate-y-0 xs:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 xs:scale-100"
          leave-to="opacity-0 translate-y-4 xs:translate-y-0 xs:scale-95"
        >
          <DialogPanel
            :class="[
              'relative transform overflow-hidden rounded-t-2xl xs:rounded-2xl bg-grey-50 text-left shadow-xl transition-all xs:w-full ',
              `size-${size}`,
              noPadding ? '' : 'xs:my-8 px-6 py-4',
            ]"
          >
            <slot />
            <Button
              class="absolute right-6 top-5"
              v-if="!noAutoClose"
              tabindex="-1"
              color="dark"
              variant="white"
              size="xs"
              @click="handleClose"
            >
              <Icon icon="x" class="h-4 w-4" />
            </Button>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped lang="postcss">
.size-xs {
  @apply max-w-xs;
}

.size-sm {
  @apply max-w-sm;
}

.size-base {
  @apply max-w-md;
}

.size-lg {
  @apply max-w-lg;
}

.size-xl {
  @apply max-w-xl;
}

.size-2xl {
  @apply max-w-2xl;
}

.size-3xl {
  @apply max-w-3xl;
}
</style>
