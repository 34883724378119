import type { Ref, ComputedRef } from 'vue'

interface Store {
  loading: Ref<boolean>
  loaded?: ComputedRef<boolean>
  init: (opt?: any) => void
  get?: (opt: any) => Promise<boolean>
  update?: (opt: any) => Promise<boolean>
  clear?: () => void
}

export interface SingleStore extends Store {
  item: Ref
}

export interface ListStore extends Store {
  items: Ref
  list: Ref<string[]>
  total?: Ref<number>
  add?: (opt: any) => Promise<boolean>
  remove?: (opt: any) => Promise<boolean>
}

export interface ManageStoreOptions {
  loading: Ref<boolean>
  request: () => Promise<any>
  success?: (result: any) => void
}

export const manageStore = async ({ loading, request, success }: ManageStoreOptions): Promise<boolean> => {
  loading.value = true
  const result = await request()
  if (result && success) success(result)
  loading.value = false
  return !!result
}
