<script setup lang="ts">
import { ref } from 'vue'

import pkg from '~/package.json'

import { useDraggable } from '@vueuse/core'
import { useUserStore } from '@/merchant/stores/user'
import { useI18n } from 'vue-i18n'

import { showToast } from '@/core/hooks'

import { postPointOfSaleClosure } from '@/merchant/api/pointOfSale'

const user = useUserStore()
const { locale, availableLocales } = useI18n()

const showDebug = ref(false)
const debug = ref<HTMLElement | null>(null)

const { style: debugStyle } = useDraggable(debug, {
  initialValue: { x: 40, y: 40 },
})

const apiUrl = import.meta.env.VITE_BASE_URL

const currentEnv = computed<'dev' | 'preprod' | 'edge' | 'production'>(() => {
  if (/localhost/.test(apiUrl)) return 'dev'
  if (/127.0.0.1/.test(apiUrl)) return 'dev'
  if (/preprod/.test(apiUrl)) return 'preprod'
  if (/edge/.test(apiUrl)) return 'edge'
  if (/production/.test(apiUrl)) return 'production'
  return 'production'
})

const isDebug = computed(() => !!['dev', 'preprod', 'edge'].includes(currentEnv.value))

const debugValues = [
  { title: 'Env' },
  { key: 'env', label: 'Current env', value: computed(() => currentEnv) },
  { separator: true },
  { title: 'Datas' },
  { key: 'company_id', label: 'Current Company Id', value: computed(() => user.activeCompanyId) },
  { key: 'pos_id', label: 'Current POS Id', value: computed(() => user.activePointOfSaleId) },
  { key: 'checkout_id', label: 'Current Checkout id', value: computed(() => user.currentCheckoutNumber) },
  { separator: true },
  { title: 'Locales' },
  ...availableLocales.map((_locale) => ({
    key: `locale_${_locale}`,
    label: `Force locale: ${_locale}`,
    callback: () => {
      locale.value = _locale
    },
  })),
  { separator: true },
  { title: 'Version' },
  { key: 'version', label: 'Current Version', value: pkg.version },
  { separator: true },
  { title: 'Delete account' },
  {
    key: 'delete_account',
    label: 'Delete account',
    callback: async () => {
      postPointOfSaleClosure({ companyId: user.currentCompanyId, pointOfSaleId: user.currentPointOfSaleId })
      showToast({ type: 'success', message: 'Account closure requested' })
    },
  },
]
</script>

<template>
  <div v-if="isDebug" class="fixed z-10 flex justify-start p-1 bg-grey-900/50">
    <button @click="showDebug = true" class="text-red-500 font-bold text-xl first-letter:capitalize">
      {{ currentEnv }} mode
    </button>
  </div>

  <div
    v-if="isDebug && showDebug"
    ref="debug"
    class="fixed cursor-move bg-gray-900 text-white font-bold p-3 text-xxs z-50 max-w-96 overflow-hidden flex flex-col gap-1"
    :style="debugStyle"
  >
    <div class="flex justify-end mb-1" @click="showDebug = false">
      <Icon icon="x" class="h-4 w-4" />
    </div>
    <div v-for="(debugValue, key) of debugValues" :key="key">
      <p v-if="'title' in debugValue" class="font-bold text-lg">{{ debugValue.title }}</p>
      <hr v-if="'separator' in debugValue" />
      <pre v-if="'value' in debugValue">{{ debugValue.label }}: {{ debugValue.value }}</pre>
      <div v-else-if="'callback' in debugValue" class="flex">
        <pre>{{ debugValue.label }}:</pre>
        <button class="bg-red-500 rounded px-2 ml-2" @click="debugValue.callback">Click me</button>
      </div>
    </div>
  </div>
</template>
