<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'

import { captureException } from '@sentry/vue'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'

import { Loader } from '@googlemaps/js-api-loader'

import { showToast } from '@/core/hooks'
import { setLocale, type ApiError, type ApiErrorResponse } from '@/core/api/client'
import { useLayout } from '@/core/composables/useLayout'

import useTransaction from '@/merchant/composables/useTransaction'

// @TODO: Remove me some day
import bus from '@/core/bus/eventBus'

import { env } from '~/env'

import RefreshSwNotification from '@/core/components/RefreshSwNotification.vue'

const { transaction } = useTransaction()
const router = useRouter()
const { layout } = useLayout()
const { t, locale } = useI18n()

const route = useRoute()

bus.on('api:message', (message: string, type: 'success' | 'error' = 'success') => {
  showToast({ type, message })
})

bus.on('api:errors', ({ errors, code }: ApiErrorResponse) => {
  if (code === 401 && route?.meta?.only === 'auth') {
    router.push({ name: 'public.root' })
  }
  if (!errors?.length) return
  const formattedErrors = (errors as ApiError[]).map((error) => error.messages.join('\n'))
  formattedErrors.forEach((err) => {
    captureException(new Error(`[API Error] ${err}`))
    showToast({
      type: 'error',
      message: t('errors.fatal'),
    })
  })
})

onMounted(() => {
  // @ts-ignore - Here, typescript is wining because for watherver rason,
  // the type inference of "locale" who is coming from "useI18n"
  // is not typed properly...
  setLocale(locale.value)
})

const mapLoader = new Loader({
  apiKey: env.VITE_GOOGLE_PLACES_KEY,
  libraries: ['places'],
})

mapLoader.load().then((google) => {
  window.google = google
})

const isDev = import.meta.env.DEV
</script>

<template>
  <RefreshSwNotification class="z-50" />
  <DebugPanel />

  <component :is="layout">
    <router-view />
  </component>
  <Teleport to="body">
    <d-prompt class="z-50" />
    <TransactionModal v-if="transaction" :modelValue="true" :transaction="transaction" />
    <VueQueryDevtools v-if="isDev" />
  </Teleport>
</template>
