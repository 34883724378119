<script setup lang="ts">
import { onMounted } from 'vue'
import { cguPdf, contractPdf } from '@/core/utils/constant'
import { useGtm } from '@gtm-support/vue-gtm'
import { useRoute } from 'vue-router/auto'

import SepaMandate from './SepaMandate.vue'

type Docs = 'cgu' | 'contract' | 'mandate'

const emit = defineEmits(['close', 'sign'])
const props = withDefaults(
  defineProps<{
    show?: Docs[]
    mandateOpen?: boolean
  }>(),
  {
    show: () => ['cgu', 'contract', 'mandate'],
    mandateOpen: false,
  },
)

const gtm = useGtm()
const route = useRoute()
onMounted(() => {
  gtm?.trackView('merchant_onboarding_sign', route.path)
})

const _trackClickCgu = () => {
  gtm?.trackEvent({
    event: 'general_condition_service_opening',
    category: 'merchant_onboarding',
    action: 'click',
  })
}

const _trackClickContract = () => {
  gtm?.trackEvent({
    event: 'contract_service_opening',
    category: 'merchant_onboarding',
    action: 'click',
  })
}

const showMandate = ref(props.mandateOpen || false)
const toggleMandate = () => {
  showMandate.value = !showMandate.value
  _trackClickSepaMandate(showMandate.value)
}
const _trackClickSepaMandate = (value: boolean) => {
  gtm?.trackEvent({
    event: 'sepa_mandate',
    category: 'merchant_onboarding',
    action: 'click',
    value: value ? 'open' : 'close',
  })
}
</script>

<template>
  <div class="flex flex-col gap-2">
    <div class="flex gap-2 items-center bg-grey-50 text-lg font-bold">
      <d-icon icon="signature" class="text-primary" size="md" />
      {{
        show.includes('mandate') && show.length === 1
          ? $t('settings.signature_card.mandate_title')
          : $t('settings.signature_card.service_opening_title')
      }}
    </div>
    <p>{{ $t('settings.signature_card.description') }}</p>
    <div class="flex flex-col">
      <a
        v-if="show.includes('cgu')"
        @click="_trackClickCgu"
        :href="cguPdf"
        class="py-3 border-b border-gray-300 flex items-center px-2"
        :class="{ 'border-b': show.includes('contract') || show.includes('mandate') }"
        target="_blank"
      >
        <p class="flex-grow">{{ $t('settings.signature_card.cgu') }}</p>
        <ph:arrow-square-out class="w-6 h-6" />
      </a>
      <a
        v-if="show.includes('contract')"
        @click="_trackClickContract"
        :href="contractPdf"
        class="py-3 border-gray-300 flex items-center px-2"
        :class="{ 'border-b': show.includes('mandate') }"
        target="_blank"
      >
        <p class="flex-grow">{{ $t('settings.signature_card.payment_service_contract') }}</p>
        <ph:arrow-square-out class="w-6 h-6" />
      </a>
      <div v-if="show.includes('mandate')" class="py-3 flex items-center px-2 cursor-pointer" @click="toggleMandate">
        <p class="flex-grow">{{ $t('settings.signature_card.sepa_payment_mandate') }}</p>
        <mdi:chevron-down class="w-6 h-6" />
      </div>
      <div v-if="showMandate && show.includes('mandate')" class="px-2">
        <SepaMandate />
      </div>
    </div>
    <hr />
    <div class="grid grid-cols-2 gap-2 mt-4">
      <d-button :text="$t('common.cancel')" color="dark" variant="text" @click="emit('close')" />
      <d-button :text="$t('common.accept')" @click="emit('sign')" />
    </div>
  </div>
</template>
